import * as Actions from './leaveAction';

const initialState = {
    applyLeaveResponse: "",
    leaveBalance: "",
    leaveHistory: "",
    leaveTypes: "",
    leaveDuration: ""
}

const leaveReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SET_APPLY_LEAVE_RESPONSE:
            return {
                ...state,
                applyLeaveResponse: action.payload
            }
        case Actions.SET_LEAVE_BALANCE:
            return {
                ...state,
                leaveBalance: action.payload
            }
        case Actions.SET_LEAVE_HISTORY:
            return {
                ...state,
                leaveHistory: action.payload
            }
        case Actions.SET_LEAVE_TYPES:
            return {
                ...state,
                leaveTypes: action.payload
            }
        case Actions.SET_LEAVE_DURATION:
            return {
                ...state,
                leaveDuration: action.payload
            }
        default:
            return state
    }
}

export default leaveReducer
