import * as  Actions from './letterAction'

const initialState = {
    HRlookUpData: []
}

const letterReducers = (state = initialState, action = {}) => {
    
    // Updated to if statement for sonar qube issue related to less conditional statements
    if (action.type === Actions.SET_HRLOOKUP) {
        return {
            HRlookUpData: action.payload.lookUpData ? action.payload.lookUpData : state.HRlookUpData,
            selectedRequestType: action.payload.selectedRequestType,
        }
    }
    else {
        return state
    }
}

export { letterReducers }
export default letterReducers
