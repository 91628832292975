import * as  Actions from './eventDetailAction'

const initialState = {
    dealDetail: {}
  }

const eventDetailReducers = (state = initialState, action={}) => {
    switch(action.type) {
    case Actions.GET_EVENT_DETAILS_DATA:
        return {
            eventDetail: action.payload.data,
        }
    case Actions.SET_ED_RESPONSE:
        return {
            ...state,
            response:action.payload
        }
    default:
        return state
    }
}

export { eventDetailReducers }
export default eventDetailReducers
