export const MESSAGE_PREFIX = 'Web_To_RN';

export const BLOCKED_DH_DOMAINS_FOR_SALARY = [
    "dda.gov.ae",
    "dmc.ae",
    "dkp.ae",
    "dubaiindustrialpark.ae",
    "DubaiIndustrialcity.ae",
    "mpn.ae",
    "tecomgroup.ae",
    "axs.ae",
    "DIC.ae",
    "tecom.ae",
    "tamdeen.ae",
    "emiratestowers.ae",
    "in5.ae",
    "outsourcecity.ae",
    "doneevents.com",
    "dubaicm.ae",
    "filmdubai.gov.ae",
    "dic.ae",
    "productioncity.ae",
    "doz.ae",
    "arn.ae",
    "globalvillage.ae",
    "albayan.ae",
    "dsp.ae",
    "dubaiinternetcity.ae",
    "diacedu.ae",
    "dubaigroup.com",
    "dubaiindustrialcity.ae",
    "ddfc.ae",
    "didi.ae",
    "arabmediagroup.ae",
    "ddfc.gov.ae",
    "carnaval.ae",
    "doneevents.com",
    "dubaidesigndistrict.com",
    "studiocity.ae",
    "arabmediagrouponline.com"
];

const CURRENT_ENVIRONMENT = window.location.origin.toLowerCase();

const ENVIRONMENT_MAPPING = {
    dev: {
        react: ["https://oneappdxpdevui.azurewebsites.net", "http://localhost:3000", "https://oneappdxpuatui.azurewebsites.net"]
    },
    test: {
        react: ["https://onedh-test.azurewebsites.net", "http://localhost:3000"],
    },
    prod: {
        react: ["https://oneappdxpprodui.azurewebsites.net", "https://one.dubaiholding.com"],
    }
}

export {
    CURRENT_ENVIRONMENT,
    ENVIRONMENT_MAPPING,
}
