import * as  Actions from './action'

const initialState = {
    releaseNotes: {}
  }

const releaseNotesReducers = (state = initialState, action={}) => {
    switch(action.type) {
    case Actions.GET_RELEASE_NOTES_DATA:
        return {
            releaseNotes: action.payload.data,
        }
    case Actions.SET_RELEASE_NOTES_RESPONSE:
        return {
            ...state,
            response:action.payload
        }
    default:
        return state
    }
}

export { releaseNotesReducers }
export default releaseNotesReducers
