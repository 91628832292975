import * as Actions from './poNotificationsActions'

const initialState = {
    poDetail: [],
    poAttachments: [],
  }

const poNotificationsReducers = (state = initialState, action={}) => {
    switch(action.type) {
    case Actions.GET_PO_DETAILS:
        return {
            poDetail: [action.payload, ...state.poDetail],
            poAttachments: state.poAttachments
        }
    case Actions.GET_PO_ATTACHMENTS:
        return {
            poDetail: state.poDetail,
            poAttachments: [action.payload, ...state.poAttachments]
        }
    default:
        return state
    }
}

export { poNotificationsReducers }
export default poNotificationsReducers
