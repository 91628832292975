import ReactGA from 'react-ga'
import store from '../store'
import * as Action from './Actions'
import {
    ENVIRONMENT_MAPPING,
    CURRENT_ENVIRONMENT,
} from './Constants'

const gaTrackingId = {
    devDh: 'UA-155707704-3',
    dh: 'UA-155707704-1',
    meraas: 'UA-155707704-2'
}

const trackingEnvironment = "prod"

export const getTrackingId = () => {
    let trackingId = store.getState().generalDetails.gaTrackingId;
    if (trackingId === "" || trackingId === undefined) {
        trackingId = gaTrackingId.dh;
        if(ENVIRONMENT_MAPPING["dev"].react.includes(CURRENT_ENVIRONMENT)) {
            trackingId = gaTrackingId.devDh;
        }
        store.dispatch({ type: Action.SET_GA_TRACKING_ID, payload: trackingId });
    }
    return trackingId;
}

export const addLogToConsole = (functionName, data, trackingId) => {
    console.log("Inside " + functionName);
    console.log("In UAT, below details will be added to tracking ID " + trackingId);
    console.log(data);
    return false;
}

export const addPageView = (location) => {
    let trackingId = getTrackingId()
    if (
        (ENVIRONMENT_MAPPING[trackingEnvironment].react.includes(CURRENT_ENVIRONMENT))
        || (ENVIRONMENT_MAPPING["dev"].react.includes(CURRENT_ENVIRONMENT))
        ) {
        ReactGA.initialize(trackingId);
        ReactGA.pageview(location.pathname);
    }
    else {
        addLogToConsole("addPageView", location, trackingId);
    }
}

export const addEvent = (event) => {
    let trackingId = getTrackingId()
    if (
        (ENVIRONMENT_MAPPING[trackingEnvironment].react.includes(CURRENT_ENVIRONMENT))
        || (ENVIRONMENT_MAPPING["dev"].react.includes(CURRENT_ENVIRONMENT))
    ) {
        ReactGA.initialize(trackingId);
        ReactGA.event({
            label: event.label,
            category: event.category,
            action: event.action
        });
    } else {
        addLogToConsole("addEvent", event, trackingId);
    }
}
