import axios from "axios";
import azureAppConfig from "./azureAppConfig";
import store from "./store";

const serviceList = [
  {
    type: "SitecoreLayout",
    url: "/contentService/layout",
  },
  {
    type: "SitecoreItem",
    url: "/contentService/getItem",
  },
  {
    type: "SitecoreSearch",
    url: "/contentService/search",
  },
  {
    type: "SitecoreSearchExec",
    url: "/contentService/searchExec",
  },
  {
    type: "SitecoreDetail",
    url: "/contentService/detail",
  },
  {
    type: "HRMS",
    url: "/hrmsService/hrms",
  },
  {
    type: "One",
    url: "/oneService/common",
  },
  {
    type: "Notification",
    url: "/oneService/notification",
  },
  {
    type: "Routes",
    url: "/clientService/routes",
  },
  {
    type: "Register",
    url: "/hrmsService/register",
  },
  {
    type: "GETHOTELDETAILS",
    url: "/hrmsService/hotelService",
  },
  {
    type: "RESERVATION",
    url: "/hrmsService/hotelReservation",
  },
  {
    type: "CALENDARPRICING",
    url: "/hrmsService/calendarPrice",
  },
  {
    type: "ROOMAVALIABILITY",
    url: "/hrmsService/roomavaliability",
  },
  {
    type: "GETROOMINFORMATION",
    url: "/hrmsService/roominformation",
  },
  {
    type: "POSTROOMBOOKING",
    url: "/hrmsService/payment",
  },
  {
    type: "SitecoreCategory",
    url: "/contentService/getCategory",
  },
  {
    type: "SitecoreCategoryJHB",
    url: "/contentService/getJHBConfiguration",
  },
  {
    type: "SitecoreCategoryFeedback",
    url: "/contentService/getFeedbackCatagory",
  },
  {
    type: "Purchase",
    url: "/orderService/placeOrder",
  },
  {
    type: "GeantOrderCount",
    url: "/orderService/getStorestatus",
  },
  {
    type: "RestaurantOrder",
    url: "/orderService/restaurantOrder",
  },
  {
    type: "EmailRestriction",
    url: "/oneService/emailRestriction",
  },
  {
    type: "EmpFeedbackUpdate",
    url: "/hrmsService/employeeFeedbackPost",
  },
  {
    type: "EmpFeedbackHistotyRecive",
    url: "/hrmsService/employeeFeedbackHistoryRecive",
  },
  {
    type: "EmpFeedbackHistoryProvide",
    url: "/hrmsService/employeeFeedbackHistoryProvide",
  },
  {
    type: "recognize",
    url: "/hrmsService/postRecognize",
  },
  {
    type: "awards",
    url: "/hrmsService/getSpotonDetails",
  },
  {
    type: "nominations",
    url: "/hrmsService/getSpotonDetails",
  },
  {
    type: "sitecoreAwardCategory",
    url: "/contentService/getawardtypes",
  },
  {
    type: "contactDetails",
    url: "/hrmsService/contactDetails",
  },
  {
    type: "getContactCard",
    url: "/hrmsService/contact",
  },
  {
    type: "deliveryDetails",
    url: "/orderService/deliveryDetails",
  },
  {
    type: "contactAddress",
    url: "/orderService/contactAddress",
  },
  {
    type: "retrieveOrders",
    url: "/orderService/retrieveOrders",
  },
  {
    type: "contactDetails",
    url: "/hrmsService/contactDetails",
  },
  {
    type: "getContactCard",
    url: "/hrmsService/contact",
  },
  {
    type: "requestFeedback",
    url: "/hrmsService/requestFeedback",
  },
  {
    type: "shareFeedback",
    url: "/hrmsService/share",
  },
  {
    type: "sentRequests",
    url: "/hrmsService/sentRequests",
  },
  {
    type: "voting",
    url: "/hrmsService/votingDetails",
  },
  {
    type: "votingDetails",
    url: "/hrmsService/votingDetails",
  },
  {
    type: "sitecoreInfoCategory",
    url: "/contentService/getinfotypes",
  },
  {
    type: "sitecoreEmergencyCategory",
    url: "/contentService/getemergencytypes",
  },
];

const serviceURL = (requestType) => {
  let Path;
  let serviceType = serviceList.filter(
    (service) => service.type === requestType
  );
  if (process.env["NODE_ENV"] === "development") {
    Path = "http://localhost:3002" + serviceType[0].url;
  } else {
    Path = serviceType[0].url;
  }
  return Path;
};

async function generateToken() {
  const userAgentApplication = store.getState().loginDetails
    .userAgentApplication;
  const account = userAgentApplication.getAllAccounts()[0];
  console.log(account,"account")

  let accessTokenv2;
  if (userAgentApplication.clientId === azureAppConfig.meraas.appId) {
    accessTokenv2 = await userAgentApplication.acquireTokenSilent({
      scopes: azureAppConfig.meraasscopesv2,
      account: account
    });
    return accessTokenv2.accessToken;
  } 
  if (account.idTokenClaims.aud === azureAppConfig.eahm.appId) {
    accessTokenv2 = await userAgentApplication.acquireTokenSilent({
      scopes: azureAppConfig.eahmscopesv2,
      account: account
    });
    return accessTokenv2.accessToken;
  }
  if (account.idTokenClaims.aud === azureAppConfig.dh.appId) {
    console.log("account1")
    accessTokenv2 = await userAgentApplication.acquireTokenSilent({
      scopes: azureAppConfig.scopesv2,
      account: account
    });
    return accessTokenv2.accessToken;
  }
}
export default class restService {
  async oneGetService(param) {
    let servicePath = serviceURL(param.requestType);
    return axios({
      url: servicePath,
      method: "GET",
      params: param.data,
      headers: {
        "Content-Type": "application/json",
        AuthToken: param.token,
        Authorization: await generateToken(),
      },
    });
  }

  async onePostService(param) {
    let servicePath = serviceURL(param.requestType);
    const token = await generateToken();
    return axios({
      url: servicePath,
      method: "POST",
      data: param.data,
      headers: {
        "Content-Type": "application/json",
        AuthToken: param.token,
        Authorization: token,
      },
    });
  }

  async oneAuthorizeService(param) {
    let servicePath;
    if (process.env["NODE_ENV"] === "development") {
      servicePath = "http://localhost:3002/hrmsService/authorize";
    } else {
      servicePath = "/hrmsService/authorize";
    }

    return axios({
      url: servicePath,
      method: "GET",
      headers: {
        v1authorization: param.v1accessToken,
        v2authorization: param.v2accessToken,
        Authorization: await generateToken(),
      },
    });
  }
}
