import * as Actions from './HotelListAction'
const initialState = {
 roomAvaliableData: {},
 viewedRoomAvailableData: []
}
const RoomAvaliableResponseReducer = (state = initialState, action = {}) => {
 
 // Updated to if statement for sonar qube issue related to less confitional statements
 if (action.type === Actions.SET_HOTEL_LIST_DATA_ROOMAVALIABLE) {
 return {
 roomAvaliableData: action.payload.data,
 viewedRoomAvailableData: [...state.viewedRoomAvailableData, action.payload.data.availablity_price]
 }
 }
 else {
 return state
 }
}
export { RoomAvaliableResponseReducer }
export default RoomAvaliableResponseReducer