import * as Actions from './eventsCarouselAction';

const initialState = {
    offerContentData: {},
}

const eventCarouselReducer = (state = initialState, action = {}) => {

    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.SET_EVENTS_CONTENT) {
        return {
            eventsContentData: action.payload.data
        }
    }
    else {
        return state
    }
}

export default eventCarouselReducer;
