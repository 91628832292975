import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Row, Col } from 'reactstrap';
import HamBurger from '../hamBurger/hamBurger';
import OrgLogo from '../orgLogo/orgLogo';
import SearchBar from '../searchBar/searchBar';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import EmployeeCard from "../../features/digitalCard/components/employeeCard";
import * as Actions from '../../features/urbanFoods/urbanFoodAction';
import { oneDispatchAction } from '../../providers/action';
import './header.scss';

class Header extends Component {

    state = {
        geantPages: ["Géant", "Géant Search", "Géant Confirmation"],
        noEmployeeSearchPages: [
            "Géant", "Géant Search", "Géant Confirmation",
            "Food Delivery", "Document Center",
            "PageViewer", "PageViewer-AllowNew"
        ],
        showIDtogggle:false,
        employeeDetail: null
    }

    componentDidMount() {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('route')) {
            let routeTo = urlParams.get('route');
            routeTo = (routeTo.startsWith("/")) ? routeTo : ("/" + routeTo);
            this.props.history.push(routeTo);
        }
    }

    closeGeantSearch() {
        const userSearchKeyGeant = document.getElementById("userSearchKeyGeant").value;
        this.props.oneDispatchAction(Actions.SET_USER_SEARCH_KEY, userSearchKeyGeant);
        const userSearchCategoryGeant = document.getElementById("userSearchCategoryGeant").value;
        this.props.oneDispatchAction(Actions.SET_USER_SEARCH_CATEGORY, userSearchCategoryGeant);
        this.props.history.goBack()
    }

    noTitleText(pageTitle) {
        const emptyTitle = [undefined, null, "", "PageViewer", "PageViewer-AllowNew"];
        if (window.location.pathname === "/") return true;
        if (emptyTitle.includes(pageTitle)) return true;
        return false
    }

    dynamicMenuIcon() {
        const { history, pageTitle } = this.props;
        if (pageTitle.indexOf("PageViewer") >= 0) {
            return (
                <div onClick={() => { history.goBack() }} className="backIcon">
                    <img className="searchIcon" src="/assets/icons/arrow_back.svg" alt="Back" />
                </div>
            );
        }
        return <HamBurger restrictions={this.props.authorizedRoutes} />
    }

    dynamicTitle() {
        const { pageTitle } = this.props;
        if (this.noTitleText(pageTitle)) {
            return <OrgLogo />
        }
        if (this.state.geantPages.includes(pageTitle)) {
            return <img className="geantLogo" src='/assets/hamburger/geantlogo_Actual.svg' alt='Géant' />;
        }
        return <div className="pageTitle">{pageTitle}</div>
    }

    dynamicSearch() {
        const props = this.props;
        if (this.state.noEmployeeSearchPages.includes(props.pageTitle)) {
            if (props.pageTitle === "Géant") {
                return (
                    <Link to="/service/geant/search" className="geantSearch" style={{ 'display': props.allProductsLoaded ? 'block' : 'none' }}>
                        <img className="searchIcon" src="/assets/homePage/searchIcon.svg" alt="Search" />
                    </Link>
                );
            }
            if (props.pageTitle === "Géant Search") {
                return (
                    <div onClick={() => { this.closeGeantSearch() }} className="geantSearch">
                        <img className="searchIcon" src="/assets/icons/close.svg" alt="Close" />
                    </div>
                );
            }
            if (props.pageTitle === "PageViewer-AllowNew") {
                const externalPageLink = document.getElementById("OnePageViewer").src;
                return (
                    <div className="geantSearch">
                        <a href={externalPageLink} target="_blank" rel="noopener noreferrer">
                            <img className="searchIcon" src="/assets/icons/openInNew.svg" alt="Open in new" />
                        </a>
                    </div>
                );
            }
            return null;
        }
        return <div className="header-sec-block">
                    <SearchBar userIdentity={props.userIdentity} {...props} />
                    <div className="eid-block" onClick={() => this.showIdModal()}>
                        <img class="searchIcon person-eid" src="/assets/icons/person-id.png" alt="Digital Id"/>
                    </div>
               </div>
    }
    componentDidUpdate(prevProps){
        if (prevProps.digitalID !== this.props.digitalID && this.props.digitalID !== undefined && this.props.digitalID !== null && this.props.digitalID !== "" && this.state.employeeDetail === null) {
            var DigitalIDdata = this.props.digitalID.data[
                Object.keys(this.props.digitalID.data)[0]
            ];
            if (
                DigitalIDdata !== null &&
                DigitalIDdata !== undefined &&
                DigitalIDdata !== "API Call error"
            ) {
                if (DigitalIDdata.X_STATUS === "Error: ORA-01403: no data found") {
                    this.setState({
                        employeeDetail: null,
                    });
                } else if (DigitalIDdata.X_STATUS !== undefined) {
                    this.setState({
                        employeeDetail: DigitalIDdata
                    });
                }
            }
        }
    }

    showIdModal(){
        this.setState({
            showIDtogggle:!this.state.showIDtogggle
        })
    }

    render() {
        return (
            <div className="header">
                <div className="fixedHeader">
                    <Row className="rowHeader">
                        <Col xs="2" md="1" lg="1" onClick={() => this.setState({showIDtogggle: false}) }>{this.dynamicMenuIcon()}</Col>
                        <Col xs="7" md="7" lg="8">{this.dynamicTitle()}</Col>
                        <Col xs="3" md="4" lg="3">{this.dynamicSearch()}</Col>
                    </Row>
                </div>

                <Modal isOpen={this.state.showIDtogggle} backdrop={true} toggle={() => this.showIdModal()} className={"eidModal mobile-popup" + (this.state.employeeDetail !== "" && this.state.employeeDetail !== null ? "eid-available" : "")}>
                    <ModalHeader toggle={() => this.showIdModal()}>Digital Card</ModalHeader>
                    <ModalBody>
                        {this.state.employeeDetail !== "" && this.state.employeeDetail !== null ?
                            <div className="discount-id-block">
                                <div className="cardContainer">
                                    <EmployeeCard discounts={true}
                                        userIdentity={this.props.userIdentity}
                                        key={this.state.employeeDetail}
                                        employeeDetail={this.state.employeeDetail}
                                    />
                                </div>
                            </div> :
                            <div className="discount-id-block">


                                <span class="material-icons material-icons-round discount-default-profile">
                                    account_circle
                                </span>
                                <br />

                                <span>{this.props.userIdentity.NAME}</span>
                                <div>{this.props.userIdentity.COMPANYNAME} - {this.props.userIdentity.EID}</div>

                                <img
                                    className="employee-id-logo"
                                    src={`/assets/logos/Logos_Small_Size/DH.JPG`}
                                    alt="Group Logo" style={{ "width": "100px" }}
                                />
                            </div>}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageTitle: state.pageDetails.pageTitle,
        allProductsLoaded: state.urbanFoodDetails.allProductsLoaded,
        authorizedRoutes: state.routesDetails.authorizedRoutes,
        digitalID: state.digitalCardDetails.digitalID,
        userIdentity: state.loginDetails.userIdentity
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            oneDispatchAction: oneDispatchAction
        },
        dispatch
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
