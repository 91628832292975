import * as Action from "./virtualBusinessCardAction";

const initialState = {
  contact: {},
};

const virtualBusinessCardReducer = (state = initialState, action = {}) => {
  if (action.type === Action.SET_CONTACT) {
    return {
      ...state,
      contact: action.payload,
    };
  } 
  else{
    return state;
  }
    
  
};

export {virtualBusinessCardReducer}
export default virtualBusinessCardReducer;
