import * as Actions from './tileComponentActions';

const initialState = {
    eventsContentData: {},
    dealfilter:"",
    eventfilter:"",
    jobfilter:"",
    discountfilter:"",
    discountData: ""
}

const discounttileContentReducer = (state = initialState, action = {}) => {
    
    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.SET_DISCOUNTCONTENT) {
        return {
            ...state,
            discounttileContentData: action.payload.data
        }
    }
    if (action.type === Actions.SET_DISCOUNTTILECONTENT) {
        return {
            ...state,
            discountData: action.payload
        }
    }
    if (action.type === "SET_DEALFILTER") {
        
        return {
            ...state,
            dealfilter: action.payload
        }
    }
    if (action.type === "SET_JOBFILTER") {
        
        return {
            ...state,
            jobfilter: action.payload
        }
    }
    if (action.type === "SET_EVENTFILTER") {
        return {
            ...state,
            eventfilter: action.payload
        }
    }
    if (action.type === "SET_DISCOUNTFILTER") {
        return {
            ...state,
            discountfilter: action.payload
        }
    }

    else{
        return state
    }
}

export default discounttileContentReducer;
