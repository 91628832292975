import * as  Actions from './actions'

const initialState = {
    authorizedRoutes: {}
  }

const routesReducers = (state = initialState, action={}) => {
    switch(action.type) {        
    case Actions.GET_AUTHORIZED_ROUTES:
        return {
            authorizedRoutes: action.payload.data,
        }
    default:
        return state
    }
}



export { routesReducers }
export default routesReducers
