import * as Actions from './pageTitleAction';

const initialState = {
    pageTitle: "",
}

const pageTitleReducer = (state = initialState, action = {}) => {
    if (action.type === Actions.SET_PAGE_TITLE) {
        return {
            pageTitle: action.payload
        }
    }
    else{
        return state
    }
}

export default pageTitleReducer
