export const SET_PARENT_CATEGORY_LIST = "SET_PARENT_CATEGORY_LIST"
export const SET_SELECTED_PARENT_CATEGORY_ID = "SET_SELECTED_PARENT_CATEGORY_ID"
export const SET_SELECTED_PARENT_CATEGORY = "SET_SELECTED_PARENT_CATEGORY"
export const SET_SUB_CATEGORY_LIST = "SET_SUB_CATEGORY_LIST"
export const SET_SELECTED_SUB_CATEGORY = "SET_SELECTED_SUB_CATEGORY"
export const SET_ALL_PRODUCT_ITEMS = "SET_ALL_PRODUCT_ITEMS"
export const SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS"
export const SET_TOTAL_AMOUNT = "SET_TOTAL_AMOUNT"
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE"
export const SET_DELIVERY_AREA_LIST = "SET_DELIVERY_AREA_LIST"
export const SET_DELIVERY_FORM_DETAILS = "SET_DELIVERY_FORM_DETAILS"
export const SET_USER_SEARCH_KEY = "SET_USER_SEARCH_KEY"
export const SET_USER_SEARCH_CATEGORY = "SET_USER_SEARCH_CATEGORY"
export const SET_FLAG_ALL_PRODUCTS_LOADED = "SET_FLAG_ALL_PRODUCTS_LOADED"
export const SET_IS_DELIVERY_AVAILABLE = "SET_IS_DELIVERY_AVAILABLE"