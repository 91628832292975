import {
  SET_DIGITALID, SET_SCRESPONSE
} from "./digitalCardAction";

const initialState = {
  digitalID: "",
  scresponse: ""
};

const digitalCardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_DIGITALID:
      return {
        ...state,
        digitalID: action.payload
      };
    case SET_SCRESPONSE:
      return {
        ...state,
        scresponse: action.payload
      }
    default:
      return state;
  }
};

export default digitalCardReducer;
