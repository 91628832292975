import React from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { onePostAction, oneDispatchAction } from '../../providers/action'
import RestConfig from "../../providers/restConfig";

class GetEmployeeHRMSDetails extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            profileData: {},
            disabledEntities: []
        }
    }

    componentDidMount = () => {
        this.makeDigitalIdAPI()
    }

    makeDigitalIdAPI = () => {
        if (this.props.digitalID === "") {
            if (this.state.disabledEntities.includes(this.props.userIdentity.ENTITY)) {
                let digitalIdData = {
                    status: 200,
                    data: {
                        DIGITAL_ID: {
                            X_POSITION_NAME: this.props.userIdentity.DESIGNATION
                        }
                    }
                }
                this.props.oneDispatchAction("SET_DIGITALID", digitalIdData)
            }
            else {
                let serviceType = RestConfig.filter(data => data.type === "DIGITALID")[0]
                    .endpoint;
                let serviceRequest = {
                    requestType: "HRMS",
                    token: localStorage.getItem("OneToken") || "",
                    data: {
                        type: serviceType,
                        empParam: "EMP_ID",
                        requestParam: {
                            Entity: this.props.userIdentity.ERPENTITY || ""
                        }
                    }
                };
                this.props
                    .onePostAction("SET_DIGITALID", serviceRequest)
                    .then(res => {
                        let digitalIdData = res.data[Object.keys(res.data)[0]];
                        this.setState({
                            profileData: digitalIdData
                        })
                    })
                    .catch(error => {
                        console.log("Error", error);
                    });
            }

        }
    }

    render() {
        return null
    }
}

const mapStateToProps = state => {
    return {
        userIdentity: state.loginDetails.userIdentity,
        digitalID: state.digitalCardDetails.digitalID
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onePostAction: onePostAction,
            oneDispatchAction: oneDispatchAction
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GetEmployeeHRMSDetails);
