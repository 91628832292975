import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { oneGetAction } from "../../providers/action";
import MenuToRight from "./atoms/animation/menuToRight";
import "./hamBurger.scss";

const HamBurger = (props) => {

  const userAgentApplication = useSelector(state => state.loginDetails.userAgentApplication);
  const userIdentity = useSelector(state => state.loginDetails.userIdentity);
  const restrictions = props.restrictions;
  const userEntity = userIdentity.ENTITY;
  const currentUser = userIdentity.MAIL.toLowerCase();

  // All Menu items before apply any restriction
  const [menuItemsInitial] = useState([
    {
      icon: "/assets/hamBurger/Deals.svg",
      name: "Offers",
      route: "/deals/all/discounts",
    },
    {
      icon: "/assets/hamBurger/events.svg",
      name: "Events",
      route: "/events/all",
    },
    {
      icon: "/assets/hamBurger/Services.svg",
      name: "Services",
      route: "/allServices",
    },
    {
      icon: "/assets/hamBurger/png/appreciation.png",
      name: "Appreciation Box",
      route: "/performance/spoton/nominate",
    },
    {
      icon: "/assets/hamBurger/News.svg",
      name: "News",
      route: "/news",
    },
    {
      icon: "/assets/hamBurger/cafe.svg",
      name: "DH Cafe",
      route: "/cafe",
    },
    {
      icon: "/assets/hamBurger/Message.svg",
      name: "Leadership Messages",
      route: "/messages",
    },
    {
      icon: "/assets/hamBurger/folder.svg",
      name: "Document Center",
      route: "/documents",
    },
    {
      icon: "/assets/hamBurger/FAQ.svg",
      name: "About DHHQ",
      route: "/aboutDhhq/dhhq",
    },
    {
      icon: "/assets/hamBurger/Account.svg",
      name: "My Account",
      route: "/allAccounts",
    },
    // {
    //   icon: "/assets/hamBurger/Message.svg",
    //   name: "Release Notes",
    //   route: "/releaseNotes",
    // },
    //,
    // {
    //   icon: "/assets/hamBurger/events.svg",
    //   name: "Oracle Fusion",
    //   route: "/oracleFusion",
    // },
    // {
    //   icon: "/assets/hamBurger/Deals.svg",
    //   name: "Talent Connect",
    //   route: "/jobs/all",
    // },
  ]);

  // All Menu items after applied restriction (if any)
   const [menuItemsFinal, setMenuItemsFinal] = useState([]);
   const fetchUpdatedMenuItems=()=>{
    let updatedMenuItems=[];
    menuItemsInitial && menuItemsInitial.map((element)=>{   
      if (Object.keys(restrictions).length !== 0){
        restrictions.featureList.forEach(item=>{
          if(item.fields["Feature Name"].value===element.name){
              if(item.fields["Specific Users Access"].value.includes(currentUser) || item.fields["Specific Users Access"].value.includes(userIdentity.MAIL) || !item.fields["Exclude Entities"].value.includes(userEntity)){
                      updatedMenuItems.push(element);
                  }
          }

        })
      }

})
  // updatedMenuItems.push(    {
  //   icon: "/assets/hamBurger/Deals.svg",
  //   name: "Talent Connect",
  //   route: "/jobs/all",
  // })
  setMenuItemsFinal(updatedMenuItems);
  }

  useEffect(fetchUpdatedMenuItems, [restrictions]);
  //console.log(menuItemsFinal);

  return (
    <div className="hamburger">
      <MenuToRight
        collection={menuItemsFinal}
        userAgent={userAgentApplication}
        userIdentity={userIdentity}
        restrictions={restrictions}
      />
    </div>
  );
};

export default HamBurger;
