import * as Actions from './dealsCarouselAction';

const initialState = {
    offerContentData: {},
}

const offerCarouselReducer = (state = initialState, action = {}) => {
    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.SET_OFFERS_CONTENT) {
        return {
            dealsContentData: action.payload.data
        }
    }
    else{
        return state
    }
}

export default offerCarouselReducer;
