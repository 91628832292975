import * as Actions from './leaveNotificationsActions'

const initialState = {
    leaveDetail: [],
    leaveAttachments: [],
}

const leaveNotificationsReducers = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_LEAVE_DETAILS:
            return {
                leaveDetail: [action.payload, ...state.leaveDetail],
                leaveAttachments: state.leaveAttachments
            }
        case Actions.GET_LEAVE_ATTACHMENTS:
            return {
                leaveDetail: state.leaveDetail,
                leaveAttachments: [action.payload, ...state.leaveAttachments]
            }
        default:
            return state
    }

}

export { leaveNotificationsReducers }
export default leaveNotificationsReducers
