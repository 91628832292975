import * as  Actions from './jobDetailAction'

const initialState = {
    jobDetail: {}
  }

const jobDetailReducers = (state = initialState, action={}) => {
    switch(action.type) {
    case Actions.GET_JOB_DETAILS_DATA:
        return {
            jobDetail: action.payload.data,
        }
    case Actions.SET_JD_RESPONSE:
        return {
            ...state,
            response:action.payload
        }
    default:
        return state
    }
}

export { jobDetailReducers }
export default jobDetailReducers
