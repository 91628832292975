import * as Actions from './messagesAction';

const initialState = {
    messageContentData: {},
}

const messagesReducer = (state = initialState, action = {}) => {
    
    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.SET_MESSAGE_CONTENT) {
        return {
            messageContentData: action.payload
        }
    }
    else{
        return state
    }
}

export default messagesReducer;
