import * as Actions from './cafeOffersAction';

const initialState = {
    discountResponse: [],
    transactionResponse: "",
    usageHistory: [],
    cafeMenu:""
}

const cafeReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SET_CAFE_DISCOUNTS_RESPONSE:
            return {
                ...state,
                discountResponse: action.payload
            }
        case Actions.SET_CAFE_TRANSACTION_RESPONSE:
            return {
                ...state,
                transactionResponse: action.payload
            }
        case Actions.SET_CAFE_USAGE:
            return {
                ...state,
                usageHistory: action.payload
            }
        case Actions.SET_CAFE_MENU:
            return {
                ...state,
                cafeMenu: action.payload
            }
        default:
            return state
    }
}

export default cafeReducer
