const room = {
  adults: 1,
  children: 0,
  infants: 0,
};

const initialState = {
  roomData: [],
};

const GuestDetailReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_ROOM": {
      const { roomMaxCount } = payload;
      let roomCount = roomMaxCount !== undefined ? roomMaxCount : 2;
      if (state.roomData.length < roomCount) {
        return { ...state, roomData: [...state.roomData, room] };
      } else {
        break;
      }
    }

    case "DELETE_ROOM": {
      const { id } = payload;
      return {
        ...state,
        roomData: [
          ...state.roomData.slice(0, id),
          ...state.roomData.slice(id + 1),
        ],
      };
    }
    case "RESET_ROOM":
      return { state, roomData: [] };

    case "ADULT_INCREASE_COUNT": {
      const { id } = payload;
      const roomData = state.roomData.map((item, index) => {
        if (index === id) {
          return { ...item, adults: item.adults + 1 };
        }
        return item;
      });
      return { ...state, roomData };
    }

    case "ADULT_DECREASE_COUNT": {
      const { id } = payload;
      const roomData = state.roomData.map((item, index) => {
        if (index === id) {
          return { ...item, adults: item.adults - 1 };
        }
        return item;
      });
      return { ...state, roomData };
    }

    case "CHILD_DECREASE_COUNT": {
      const { id } = payload;
      const roomData = state.roomData.map((item, index) => {
        if (index === id) {
          return { ...item, children: item.children - 1 };
        }
        return item;
      });
      return { ...state, roomData };
    }
    case "CHILD_INCREASE_COUNT": {
      const { id } = payload;
      const roomData = state.roomData.map((item, index) => {
        if (index === id) {
          return { ...item, children: item.children + 1 };
        }
        return item;
      });
      return { ...state, roomData };
    }

    case "INFANT_INCREASE_COUNT": {
      const { id } = payload;
      const roomData = state.roomData.map((item, index) => {
        if (index === id) {
          return { ...item, infants: item.infants + 1 };
        }
        return item;
      });
      return { ...state, roomData };
    }

    case "INFANT_DECREASE_COUNT": {
      const { id } = payload;
      const roomData = state.roomData.map((item, index) => {
        if (index === id) {
          return { ...item, infants: item.infants - 1 };
        }
        return item;
      });
      return { ...state, roomData };
    }

    default:
      return state;
  }
  return state;
};

export { GuestDetailReducer };

export default GuestDetailReducer;
