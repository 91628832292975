import * as Actions from "../HotelListAction";

const initialState = {
 
  paymentData:{status:0} 
};

const PaymentReducer = (state = initialState, action = {}) => {
    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.SET_PAYMENT) {
        return { ...state, paymentData: action.payload };
    } else if(action.type === Actions.RESET_PAYMENT){
      return { state, paymentData: initialState };


    }
     else {
      return {...state};
    }
  };
  
  export { PaymentReducer };
  export default PaymentReducer;
  