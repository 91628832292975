import React from "react";
import { Input } from "reactstrap";
import "./input.scss";

const InputComponent = props => {
  return (
    <Input
      id={props.id}
      className="input-email"
      innerRef={props.childRef}
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      onChange={() => props.handleChange(props.childRef)}
      valid={props.valid}
      invalid={props.invalid}
      onBlur={() => props.handleBlur(props.childRef)}
      onKeyPress={(e) => props.handleKey(e)}
      disabled={props.disabled}
      value={props?.value ? props?.value : ""}
      autoComplete={props.type === "email" ? "username" : "on"}
    />
  );
};

export default InputComponent;
