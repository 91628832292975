const MESSAGE_PREFIX = 'Web_To_RN';

// An event has occured as Token is generated and rendered by the webpage
export const onEmailReceivedEvent = (email, name, entity) => {
    // build a default payload consisting of emailID if one is not provided
    const event = 'emailIDReceived';
    const payload = {
        email: email ? email : '',
        name: name ? name : '',
        entity: entity ? entity : ''
    };
    const setPayloadEvent = {
        event,
        payload
    };
    // data to send is an object containing key value pairs that will be
    // spread into the destination's state or seen as an event
    const message = JSON.stringify({
        prefix: MESSAGE_PREFIX,
        payload: setPayloadEvent
    });

    if (document.hasOwnProperty('postMessage')) {
        document.postMessage(message, '*');
    } 
    else if (window.hasOwnProperty('postMessage')) {
        window.postMessage(message, '*');
    }
};

export const onAttachmentClickedEvent = (base64String, filename, type) => {
    // build a default payload consisting of emailID if one is not provided
    const event = 'attachmentClicked';
    const payload = {
        base64String: base64String,
        filename: filename,
        type: type
    };
    const setPayloadEvent = {
        event,
        payload
    };
    // data to send is an object containing key value pairs that will be
    // spread into the destination's state or seen as an event
    const message = JSON.stringify({
        prefix: MESSAGE_PREFIX,
        payload: setPayloadEvent
    });

    if (document.hasOwnProperty('postMessage')) {
        document.postMessage(message, '*');
    } 
    else if (window.hasOwnProperty('postMessage')) {
        window.postMessage(message, '*');
    }
};
