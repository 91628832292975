import * as Actions from "./informationModalAction"

const initialState = {
    showModalHome: true,
    showModalOffer: true,
  }

const informationModalReducer = (state = initialState, action) => {
    if(action.type === Actions.SET_SHOW_INFOMODAL_HOME) {   
        return {
            ...state,
            showModalHome: action.payload
        }
    } else if(action.type === Actions.SET_SHOW_INFOMODAL_OFFER) {   
        return {
            ...state,
            showModalOffer: action.payload
        }
    }
    else{
        return state
    }
}

export default informationModalReducer
