import RestService from "./service";
let restService = new RestService();
const jwtDecode = require("jwt-decode");

export function oneDispatchAction(type, value) {
  return (dispatch) => {
    dispatch({ type: type, payload: value });
  };
}

export function oneGetAction(type, request) {
  return function (dispatch) {
    return restService
      .oneGetService(request)
      .then((res) => {
        dispatch({ type: type, payload: res.data });
        return res;
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
}

export function onePostAction(type, request) {
  return function (dispatch) {
    return restService
      .onePostService(request)
      .then((res) => {
        type && dispatch({ type: type, payload: res.data });
        return res.data;
      })
      .catch((error) => {
        if(type === "SET_DIGITALID"){
          dispatch({ type: type, payload: null });
        }
        console.log("Error", error);
      });
  };
}

export function oneAuthorizeRenewAction(type){
  return function (dispatch) {
  const storedToken = localStorage.getItem("OneToken");
  const decodedToken = storedToken !== null && jwtDecode(storedToken);
  let EID = decodedToken.eid;
  if (!!EID && EID.indexOf("ORA") >= 0) {
    EID = "";
  }
  let userIdentity = {
    EID: EID,
    ETIER: decodedToken.etier,
    TENANT: decodedToken.tid,
    MAIL: decodedToken.mail,
    NAME: decodedToken.name,
    ENTITY: decodedToken.entity,
    ERPENTITY:decodedToken.erpEntity,
    ERPTYPE:decodedToken.erpType,
    VERTICAL:decodedToken.vertical,
    DESIGNATION: decodedToken.designation,
    UPN: decodedToken.upn,
    TOKEN: storedToken,
    FIRSTNAME: decodedToken.firstName,
    LASTNAME: decodedToken.lastName,
    PHONENUMBER: decodedToken.phoneNumber,
    COMPANYNAME: decodedToken.companyName,
    USERTYPE:decodedToken.userType
  };
  dispatch({ type: type, payload: userIdentity });
  return userIdentity;
}
}

function deleteCookies() {
  var Cookies = document.cookie.split(';');

  // set 1 Jan, 1970 expiry for every cookies
  for (var i = 0; i < Cookies.length; i++)
  document.cookie = Cookies[i] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
}

export function oneAuthorizeAction(type, request) {
  return function (dispatch) {
    return restService
      .oneAuthorizeService(request)
      .then((res) => {
        localStorage.setItem("OneToken", res.data.oneToken);
        const decodedToken = jwtDecode(res.data.oneToken);
        let EID = decodedToken.eid;
        if (!!EID && EID.indexOf("ORA") >= 0) {
          EID = "";
        }
        let userIdentity = {
          EID: EID,
          ETIER: decodedToken.etier,
          TENANT: decodedToken.tid,
          MAIL: decodedToken.mail,
          NAME: decodedToken.name,
          ENTITY: decodedToken.entity,
          ERPENTITY:decodedToken.erpEntity,
          ERPTYPE:decodedToken.erpType,
          VERTICAL:decodedToken.vertical,
          DESIGNATION: decodedToken.designation,
          UPN: decodedToken.upn,
          TOKEN: res.data.oneToken,
          FIRSTNAME: decodedToken.firstName,
          LASTNAME: decodedToken.lastName,
          PHONENUMBER: decodedToken.phoneNumber,
          COMPANYNAME: decodedToken.companyName,
          USERTYPE:decodedToken.userType
        };
        dispatch({ type: type, payload: userIdentity });
        return userIdentity;
      })
      .catch((error) => {
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        console.log("Authorize Error", error);
        // deleteCookies();
        // window.localStorage.clear();
        window.location.reload(true);
      });
  };
}
