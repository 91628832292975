import * as Actions from './spotonAction';

const initialState = {
    recognizeAward: "",
    awardHistory: "",
    nominationHistory:"",
    awardType:"",
    pointDesc:"",
}

const spotonReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SET_RECOGNIZE_RESPONSE:
            return {
                ...state,
                recognizeAward: action.payload
            }
        case Actions.SET_AWARD_HISTORY:
            return {
                ...state,
                awardHistory: action.payload
            }
        case Actions.SET_NOMINATION_HISTORY:
            return {
                ...state,
                nominationHistory: action.payload
            }
        case Actions.SET_AWARD_CATEGORIES:
                return {
                    ...state,
                    awardType: action.payload
                }
        case Actions.SET_POINT_DESCRIPTION:
                return {
                    ...state,
                    pointDesc: action.payload
                }
        default:
            return state
    }
}

export default spotonReducer
