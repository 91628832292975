import * as Actions from './dhhqAction';

const initialState = {
    infoType:"",
}

const dhhqReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SET_INFO_TYPES:
                return {
                    ...state,
                    infoType: action.payload
                }
        default:
            return state
    }
}

export default dhhqReducer
