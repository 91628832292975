import * as Actions from './generalActions'

const initialState = {
  gaTrackingId: ''
};

const generalReducer = (state = initialState, action = {}) => {
  
  // Updated to if statement for sonar qube issue related to less confitional statements
  if (action.type === Actions.SET_GA_TRACKING_ID) {
    return {
      gaTrackingId: action.payload.data
    }
  }
  else {
    return state
  }

};

export default generalReducer
