import * as  Actions from './dealDetailAction'

const initialState = {
    dealDetail: {}
  }

const dealDetailReducers = (state = initialState, action={}) => {
    switch(action.type) {
    case Actions.GET_DEAL_DETAILS_DATA:
        return {
            dealDetail: action.payload.data,
        }
    case Actions.SET_DD_RESPONSE:
        return {
            ...state,
            response:action.payload
        }
    default:
        return state
    }
}

export { dealDetailReducers }
export default dealDetailReducers
