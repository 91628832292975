
import React, { Suspense } from "react";
import { createBrowserHistory } from 'history';
import { Router } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row, Col, Button } from "reactstrap";
import Hidden from "@material-ui/core/Hidden";
import * as GA from './providers/googleAnalytics/googleAnalytics';
import * as genericService from './providers/util';
import ErrorBoundary from './components/errorBoundary/errorBoundary';
import LandingPage from './pages/landing/Landing';
import Header from './components/header/header';
import GetEmployeeHRMSDetails from './components/getEmployeeHRMSDetails/getEmployeeHRMSDetails';
import EmailRestriction from './features/emailRestriction/emailRestriction';
import EmbedLink from './features/embedLink/embedLink';
import RouteComponents from './providers/router/router';
import "./App.scss";

const history = createBrowserHistory();
history.listen((location) => {
  GA.addPageView(location);
});

const App = () => {

  const isMobile = genericService.isMobile();
  const device = isMobile ? " device" : " window";

  // Get data from Redux store
  const { authenticated, userIdentity } = useSelector(state => state.loginDetails);

  let userType;
  if (userIdentity === null) {
    userType = "NA"
  }
  else {
    userType = userIdentity.USERTYPE
  }
  if(window.location.host.includes("uat") === true)
  {
    userType = "User Account"
  }
  const allowedUsers = "User Account,Test Account,VIP"
  return (
    <Router history={history}>
      <ErrorBoundary>
        <Suspense fallback={<div className="loadingMessage">Loading...</div>}>
          {
            authenticated && userIdentity !== null && allowedUsers.includes(userType) ?
              <div className={"app" + device}>
                <Header userIdentity={userIdentity} path={history.location.pathname} />
                <GetEmployeeHRMSDetails />
                <EmailRestriction />
                <EmbedLink />
                <RouteComponents />
              </div>
              :
              <div>
                {authenticated && userIdentity !== null && !allowedUsers.includes(userType) ?
                    	<div className="loginContainer">
                      <Container fluid={true}>
                        <Row>
                          <Col className="imageHolder" lg="8" md="12" sm="12" xs="12">
                            <Hidden smDown>
                              <div className="desktopBg">
                                <div className="desktopLogo">
                                  <img src="/media/loginLogo.svg" alt="One Logo" />
                                </div>
                              </div>
                            </Hidden>
                            <Hidden mdUp>
                              <div className="mobileBg">
                                <div className="mobileLogo">
                                  <img src="/media/loginLogo.svg" alt="One Logo" />
                                </div>
                              </div>
                            </Hidden>
                          </Col>
                          <Col lg="4" md="12" sm="12" xs="12" className="login-form">
                            <div class="row user-type-message">
                              Your account is {userType}, You do not have permission to access this Application. Please raise an ESD ticket to get this issue resolved from your HR.
                              <Button
                                color="danger"
                                onClick={() => {
                                  window.localStorage.clear();
                                  window.location.reload();
                                }}
                                size="md"
                              >
                                Login
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                  </div>:
                  <LandingPage />
                }
              </div>

          }
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
