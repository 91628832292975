import * as FeedbackAction from './EmpReducerConst'

const initialState = {
  sitecoreEmpFeedback:{},
  empFeedbackPost: {},
  empFeedbackHistoryRecive:{},
  empFeedbackHistoryProvide:{},
  sentRequests:{}


};

const EmployeFeedbackReducer = (state = initialState, action = {}) => {
    if (action.type === FeedbackAction.SET_EMPFEEDBACK_SITECORE_DATA) {
        return { ...state, sitecoreEmpFeedback: action.payload };
    } else if (action.type === FeedbackAction.POST_EMP_FEEDBACK) {
      return { ...state, empFeedbackPost: action.payload };
    }else if (action.type === FeedbackAction.GET_EMP_FEEDBACK_HISTORY_RECIVE) {
      return { ...state, empFeedbackHistoryRecive: action.payload };
    }else if (action.type === FeedbackAction.GET_EMP_FEEDBACK_HISTORY_PROVIDE) {
      return { ...state, empFeedbackHistoryProvide: action.payload };
    }else if (action.type === FeedbackAction.GET_SENT_REQUESTS) {
      return { ...state, sentRequests: action.payload };
    }
     else {
      return {...state};
    }
  };
  
  export { EmployeFeedbackReducer };
  export default EmployeFeedbackReducer;
  