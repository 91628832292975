import * as Actions from "../HotelListAction";

const initialState = {
  selectedSuite: [],
};

const SelectedSuiteReducer = (state = initialState, action = {}) => {
  // Updated to if statement for sonar qube issue related to less confitional statements
  if (action.type === Actions.SET_SELECTED_SUITE) {
    return { ...state, selectedSuite: action.payload };
  } else {
    return { ...state };
  }
};

export { SelectedSuiteReducer };
export default SelectedSuiteReducer;
