import React from "react";

const logoMapping = {
  "DP": 'DP.jpg',
  "Dubai CM": 'DCM.png',
  "Dubai Asset Management": 'DAM.JPG',
  "Ejadah": 'EJADAH.JPG',
  "Dubai Retail": 'DR.png',
  "Dubai Holding": 'DH.JPG',
  "Arab Media Group": 'AMG.PNG',
  "Tecom Group": 'TECOM.PNG',
  "DIDI": 'DIDI.png',
  "JG_DXB": 'Jumeirah.png',
  "JG_Int":'JUMEIRAH.png',
  "DIDI":'DIDI.png',
  "Meraas":'MERAAS.png',
  "North25" :'N25.png',
  "DHRE":'DHRE.png', 
  "DHAM":'DHAM.png',
  "DHH":'DHH.png',
  "DHE":'DHE.png',
  "Urban Foods":'UF.jpg',
  "Merex":'MEREX.png',
  "D-Marine":'DMARINE.png',
  "Valiant":'VALIANT.png',
  "DDA":'DDA.png',
  "Shamal":'SHAMAL.PNG',
  "DXBE":""
}

const employeeCard = props => {
  console.log(props)
  const logoURL = logoMapping[props.userIdentity.ENTITY] || ''
  return (
    <div className="employee-id-background">
      <div className="employee-id-block">
        <div className="employee-id-img-block">
          <div className="left">
            <img
              className="employee-id-photo"
              src={
                "data:image/png;base64," + props.employeeDetail.X_EMPLOYEE_PHOTO_B
              }
              onError={e => {
                var fallbackImage = "/assets/logos/photoNA.png";
                e.target.onerror = null;
                e.target.src = fallbackImage;
              }}
              alt="Employee"
            />
          </div>
          <div className="right">
            {logoURL !== '' ? <img
              className="employee-id-logo"
              src={`/assets/logos/Logos_Small_Size/${logoURL}`}
              alt="Group Logo"
            /> : ''}
          </div>
        </div>

        <div className="employee-id-text-block">
          <div className="digital-card-name">
            {props.employeeDetail.X_EMPLOYEE_NAME}
          </div>

          {!!props.employeeDetail.X_POSITION_NAME ? (
            <div className="digital-card-department position">
              {props.employeeDetail.X_POSITION_NAME}
            </div>
          ) : (
              ""
            )}
          {!!props.employeeDetail.X_DEPARTMENT ? (
            <div className="digital-card-department department">
              {props.employeeDetail.X_DEPARTMENT}
            </div>
          ) : (
              ""
            )}
          {!!props.employeeDetail.X_CONTACT_NUMBER && props.employeeDetail.X_CONTACT_NUMBER.nil === undefined ? (
            <div className="digital-card-detail">
              <img src="/assets/icons/phone.png" alt="Phone" />
              {props.employeeDetail.X_CONTACT_NUMBER}
            </div>
          ) : (
              ""
            )}
          {!!props.employeeDetail.X_EMAIL_ADDRESS ? (
            <div className="digital-card-detail">
              <img src="/assets/icons/mail.png" alt="Mail" />
              {props.employeeDetail.X_EMAIL_ADDRESS}
            </div>
          ) : (
              ""
            )}
          {!!props.employeeDetail.X_OFFICE ? (
            <div className="digital-card-detail">
              <img src="/assets/icons/work.png" alt="Work" />
              {props.employeeDetail.X_OFFICE}
            </div>
          ) : (
              ""
            )}
        </div>
        <br/>
        {props.discounts !== undefined && props.userIdentity.ENTITY !== "Dubai Holding" && <img
              className="employee-id-logo"
              src={`/assets/logos/Logos_Small_Size/DH.JPG`}
              alt="Group Logo" style={{"width":"100px"}}
            />
        }
        <br/>
        {props.discounts !== undefined && props.userIdentity.ETIER === '1' ?
          <div className="digital-card-detail">
            <span className="digital-exec">Dubai Holding Executive Employee</span>
          </div>
          :
          ""
        }
        
        <br/>
        {!!props.employeeDetail.X_EMPLOYEE_NUMEBR ? (
          <div className="digital-card-number">
            ID-{props.employeeDetail.X_EMPLOYEE_NUMEBR}
          </div>
        ) : (
            ""
          )}
      </div>
    </div>
  );
};

export default employeeCard;
