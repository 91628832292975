import * as Actions from "../HotelListAction";

const initialState = {
 
  sitecoreJBHData:{} 
};

const SitecoreJBHDataReducer = (state = initialState, action = {}) => {
    if (action.type === Actions.SET_JBH_SITECORE_DATA) {
        return { ...state, sitecoreJBHData: action.payload };
    } 
     else {
      return {...state};
    }
  };
  
  export { SitecoreJBHDataReducer };
  export default SitecoreJBHDataReducer;
  