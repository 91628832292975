import React,{useState} from 'react';
import { useSelector} from "react-redux";
import { Col } from "reactstrap";
import { NavLink, withRouter } from 'react-router-dom';
import './menuLink.scss';

const MenuLink = (props) => {
    const isHomePage = window.location.pathname === '/';
    let showPowerBiLink = true;
    const [showPop, setshowPop] = useState(false);
    const userIdentity = useSelector(state => state.loginDetails.userIdentity);
    const userEntity = userIdentity.ENTITY;
    const userEmail = userIdentity.MAIL.toLowerCase();
    const userERPEntity = userIdentity.ERPENTITY;

    const quickLinksAccess = props.restrictions && props.restrictions.featureList && props.restrictions.featureList.filter(item => item.fields["Feature Name"].value === "Quick Links")[0]
    const quickLinks = props.restrictions && props.restrictions.quickLinks &&  props.restrictions.quickLinks[0].fields.Links.filter(item => item.fields["Links"] !== undefined)[0]
    const vacancyLink = quickLinks && quickLinks.fields && quickLinks.fields.Links.filter(item => item.fields["Link Title"].value === userERPEntity)[0]
    const authorizedQuickLinks1 = props?.restrictions?.quickLinks && props?.restrictions?.quickLinks[0]?.fields?.Links

    let authorizedQuickLinks = []
    if(authorizedQuickLinks1?.length > 0){
        for(let link of authorizedQuickLinks1){
            let excludeEntities = []
            let tempStr = link.fields['Specific Users Access']?.value
            let specificUserAccess = tempStr ? tempStr.substring(1, tempStr.length-1).split(',') : []
            specificUserAccess = specificUserAccess?.map(user => user.substring(1, user.length-1))
            excludeEntities = link.fields['Exclude Entities']?.map(entity => entity.fields["AD Entity Code"].value)
            if(specificUserAccess?.includes(userEmail)){
                authorizedQuickLinks.push(link)
            }else if(excludeEntities !== undefined && !excludeEntities.includes(userEntity)){
                authorizedQuickLinks.push(link)
            }
        }
    }
    // const authorizedQuickLinks = props?.restrictions?.quickLinks && props?.restrictions?.quickLinks[0]?.fields?.Links?.filter(link => {
    //     console.log('*link: ', link)
    //     return !link.fields['Exclude Entities']?.includes(userEntity) && !link.fields['Specific Users Access']?.includes(userEmail)
    // })

    const toggle = () =>{
        setshowPop(true);
    } 
    const closeModal = () => 
        setshowPop(false);

    let powerBiLink = "mspbi://";
    let yammerLink = "yammer://";
    if (!window.getReactNativeApi) {
        powerBiLink = "https://app.powerbi.com/";
        yammerLink = "https://www.yammer.com/";
    }
    const home = () => {
        props.history.push("/");
        props.onClick()
    }
    const releaseNotes = () => {
        props.history.push("/releaseNotes");
        props.onClick()
    }

    const handleClearStorage = () => {
        console.log("inclear")
        window.localStorage.removeItem('OneToken');
        window.location.reload();
      };
    const signOut = () => {
        localStorage.clear()
        // localStorage.removeItem("tenantType");
        // localStorage.removeItem("OneToken");
        props.history.push("/");
        props.userAgent.logout();
    }
    const DhLinks=()=>{
        return (
            <div className="appMenuLinks">
                <div className="section1">
                    <NavLink className={`menu-item ${isHomePage ? 'active' : ''}`} to="/home" onClick={() => home()}>
                        <img className="linkIcon" src="/assets/hamBurger/Home.svg" alt="i1" />
                        <span className="linkName">Home</span>
                    </NavLink>
                    {
                        props.collection.map((item, index) => {
                            return (
                                <NavLink className="menu-item" to={item.route} onClick={props.onClick} key={index}>
                                    <img className="linkIcon" src={item.icon} alt="i1" />
                                    <span className="linkName">{item.name}</span>
                                </NavLink>
                            );
                        })
                    }
                    <NavLink className="menu-item" to="/signout" onClick={() => signOut()}>
                        <img className="linkIcon" src="/assets/hamBurger/Sign out.svg" alt="i1" />
                        <span className="linkName">Sign Out</span>
                    </NavLink>
                    {/* <button class="cache-clear menu-item clear-cache-link"onClick={() => handleClearStorage()}>
                    <span class="linkicon">X</span><span class="linkName">Clear Cache</span></button> */}
                    {/* <NavLink className="menu-item" to="/releaseNotes" onClick={() => releaseNotes()}>
                        <img className="linkIcon" src="/assets/hamBurger/message.svg" alt="i1" />
                        <span className="linkName">Release Notes</span>
                    </NavLink> */}
                </div>
                <div className="section2">
                    <div className="quickLinks">
                        <div className="menu-item">
                            <img className="linkIcon" src="/assets/hamBurger/QuickLink.svg" alt="i1" />
                            <div className="linkName"><span>Quick Links</span></div>
                        </div>
                    </div>
                    <div className="appContainer">
                        {
                            authorizedQuickLinks?.map((alink, index) => {
                                if(index < 2){
                                    return(
                                        <div className="appIcon" key={index}>
                                            <div className="externalLink">
                                            { alink?.fields?.Icon?.value?.src ? <img src={alink?.fields?.Icon?.value.src} width='40' height='40' /> : <span className="material-icons iconz">open_in_new</span>}
                                                <a href={alink?.fields?.Link?.value?.href} target="_blank" rel="noopener noreferrer">{alink?.fields['Link Title']?.value}</a>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                        <div className="appIcon">
                            <span className="more-links" onClick={()=>toggle()}>More Links ...</span>
                        </div>
                        <div className="infoPopup">
                    {showPop && (
                        <div className="modal">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3>Quick Links</h3>
                                    </div>
                                    <div className="modal-body">
                                    {
                                        authorizedQuickLinks?.map((alink, index) => {
                                            if(index > 1){
                                                return(
                                                    <Col className="appIcon" xs="12" sm="6">
                                                        <div className="externalLink">
                                                            { alink?.fields?.Icon?.value?.src ? <img src={alink?.fields?.Icon?.value.src} width='40' height='40' /> : <span className="material-icons iconz">open_in_new</span>}
                                                            <a href={alink?.fields?.Link?.value?.href} target="_blank" rel="noopener noreferrer" className="link-names">{alink?.fields['Link Title']?.value}</a>
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                        })
                                    }
                                        <div className="close-btn-wrapper">
                                            <button onClick={()=>closeModal()}>Close</button>
                                        </div></div></div></div> </div>)}</div></div></div></div>)}

    // if(userEntity==="Dubai Holding"){
        return(<DhLinks/>)
    // }
    // else{
    //     return(<div className="appMenuLinks">
    //     <div className="section1">
    //         <NavLink className={`menu-item ${isHomePage ? 'active' : ''}`} to="/home" onClick={() => home()}>
    //             <img className="linkIcon" src="/assets/hamBurger/Home.svg" alt="i1" />
    //             <span className="linkName">Home</span>
    //         </NavLink>
    //         { props.collection.map((item, index) => {
    //                 return (
    //                     <NavLink className="menu-item" to={item.route} onClick={props.onClick} key={index}>
    //                         <img className="linkIcon" src={item.icon} alt="i1" />
    //                         <span className="linkName">{item.name}</span>
    //                     </NavLink>
    //                 );
    //             })}
    //         <NavLink className="menu-item" to="/signout" onClick={() => signOut()}>
    //             <img className="linkIcon" src="/assets/hamBurger/Sign out.svg" alt="i1" />
    //             <span className="linkName">Sign Out</span>
    //         </NavLink>
    //     </div>
    //     <div className="section2">
    //         <div className="quickLinks">
    //             <div className="menu-item">
    //                 <img className="linkIcon" src="/assets/hamBurger/QuickLink.svg" alt="i1" />
    //                 <div className="linkName"><span>Quick Links</span></div>
    //             </div>
    //         </div>
    //         <div className="appContainer">
    //             <div className="appIcon">
    //                 <a href="msteams://" id="teamsLink" target="_blank" rel="noopener noreferrer">
    //                     <img className="linkIcon" src="/assets/hamBurger/Teams.svg" alt="Teams" />
    //                 </a>
    //             </div>
    //             <div className="appIcon">
    //                 <a href={yammerLink} id="yammerLink" target="_blank" rel="noopener noreferrer">
    //                     <img className="linkIcon" src="/assets/hamBurger/Yammer.svg" alt="Yammer" />
    //                 </a>
    //             </div>
    //             {showPowerBiLink &&
    //                     <div className="appIcon">
    //                         <a href={powerBiLink} id="yammerLink" target="_blank" rel="noopener noreferrer">
    //                             <img className="linkIcon" src="/assets/hamBurger/PowerBI.svg" alt="Power BI" />
    //                         </a>
    //                     </div>
    //             }
    //         </div>
    //     </div>
    // </div>)
    // }
}
export default withRouter(MenuLink);