import { lazy } from "react";

const Home = lazy(() => import("../../pages/home/home"));
const DealItems = lazy(() => import("../../pages/deals/dealItems"));
const DealDetail = lazy(() => import("../../pages/dealDetail/dealDetail"));
const EventItems = lazy(() => import("../../pages/events/eventItems"));
const EventDetail = lazy(() => import("../../pages/eventDetail/eventDetail"));
const Service = lazy(() => import("../../pages/service/service"));
const ServicesLandingPage = lazy(() =>
  import("../../pages/servicesLandingPage/servicesLandingPage")
);
const AccountsLandingPage = lazy(() =>
  import("../../pages/accountsLandingPage/accountsLandingPage")
);
const Cafe = lazy(() => import("../../pages/cafe/cafe"));
const Account = lazy(() => import("../../pages/account/account"));
const ColleagueSearch = lazy(() =>
  import("../../features/colleagueSearch/colleagueSearch")
);
const Messages = lazy(() => import("../../features/chairmanMessage/messages"));
const ReleaseNotes = lazy(() => import("../../pages/releaseNotes"));
const News = lazy(() => import("../../pages/allNews/allNews"));
const JumeirahHotelReport = lazy(() =>
  import("../../features/powerBiReports/jumeirahHotelReview")
);
const PushNotification = lazy(() =>
  import("../../features/pushNotification/PushNotification")
);
const Grocery = lazy(() => import("../../pages/grocery/grocery"));
const Restaurant = lazy(() => import("../../pages/restaurant/restaurant"));
const DocumentCenter = lazy(() =>
  import("../../components/documentCenter/documentCenter")
);
const PageViewer = lazy(() => import("../../components/pageViewer/pageViewer"));
const Performance = lazy(() => import("../../pages/performance/performance"));
const OracleFusion = lazy(() =>
  import("../../features/oracleFusion/OracleFusion")
);
const VotingForm=lazy(()=>import ('../../features/namingContest/votingForm'));
const Daleel = lazy(() => import("../../pages/daleel/daleel"));
const JobItems = lazy(() => import("../../pages/jobs/jobItems"));
const JobDetail = lazy(() => import("../../pages/jobDetail/jobDetail"));
const getDHRoutes = (authorizedItems) => {
  return [
    {
      route: "/",
      category: "Home Page",
      component: Home,
      key: "home",
      isExact: true,
    },
    {
      route: "/allServices",
      category: "All Services",
      component: ServicesLandingPage,
      items: authorizedItems.serviceItems,
      key: "service",
    },
    {
      route: "/service",
      category: "Service",
      component: Service,
      items: authorizedItems.serviceItems,
      key: "service",
    },
    {
      route: "/allAccounts",
      category: "All Account",
      name: "Account",
      component: AccountsLandingPage,
      items: authorizedItems.accountItems,
      key: "account",
    },
    {
      route: "/account",
      category: "Account",
      component: Account,
      items: authorizedItems.accountItems,
      key: "Account",
    },
    {
      route: "/deals/all",
      name: "Deal Listing",
      component: DealItems,
      items: authorizedItems,
      key: "deals"
    },
    {
      route: "/events",
      name: "Event listing ",
      component: EventItems,
      key: "events",
    },
    {
      route: "/messages",
      name: "Leadership Messages",
      component: Messages,
      key: "messages",
    },
    {
      route: "/releaseNotes",
      name: "Release Notes",
      component: ReleaseNotes,
      key: "releaseNotes",
    },
    {
      route: "/dealDetails/:dealId/:dealName",
      name: "",
      component: DealDetail,
      key: "dealDetail",
      isExact: true,
    },
    {
      route: "/eventDetails/:eventId/:eventName",
      name: "",
      component: EventDetail,
      key: "eventDetail",
      isExact: true,
    },
    {
      route: "/cafe",
      name: "DH Café",
      category: "DH Café",
      component: Cafe,
      key: "dhcafe",
    },
    {
      route: "/colleagueSearch/:searchParam",
      name: "Colleague Search",
      category: "Colleague Search",
      component: ColleagueSearch,
      key: "colleagueSearch",
      isExact: true,
    },
    {
      route: "/news",
      name: "News",
      category: "News",
      component: News,
      key: "news",
      isExact: true,
    },
    {
      route: "/jhreport",
      name: "Report",
      category: "Report",
      component: JumeirahHotelReport,
      key: "report",
      isExact: true,
    },
    {
      route: "/notifycenter",
      name: "Notificationcenter",
      category: "Notificationcenter",
      component: PushNotification,
      key: "notification",
      isExact: true,
    },

    {
      route: "/documents",
      name: "DocumentCenter",
      category: "DocumentCenter",
      component: DocumentCenter,
      key: "DocumentCenter",
      isExact: true,
    },
    {
      route: "/pageview",
      name: "PageViewer",
      category: "PageViewer",
      component: PageViewer,
      key: "PageViewer",
      isExact: true,
    },
    {
      route: "/performance",
      name: "Performance",
      component: Performance,
      key: "performance",
    },
    {
      route: "/oracleFusion",
      name: "oracleFusion",
      component: OracleFusion,
      key: "oracleFusion",
    },
    {
      route: "/voting",
      name: "voting",
      component: VotingForm,
      key: "voting",
    },
    {
      route: "/aboutDhhq",
      name: "Daleel",
      component: Daleel,
      key: "daleel",
    },
    {
      route: "/jobs/all",
      name: "Job Listing",
      component: JobItems,
      key: "talentConnect",
      isExact: true,
    },
    {
      route: "/jobDetails/:jobId/:jobName",
      name: "",
      component: JobDetail,
      key: "jobDetail",
      isExact: true,
    },
  ];
};

export { getDHRoutes };
