import React from "react";
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./orgLogo.scss";

function reload() {
  window.location.reload(true);
}

function OrgLogo(props) {
  let logoIcon = "/assets/homePage/OneLogo.png";
  let content = props.homePageDetails;
  let logoPath = content["Logo"] ? content["Logo"].value.src : logoIcon;
  return (
    <div className="orgLogo">
      <LazyLoadImage src={logoPath} alt="orgLogo" onClick={() => reload()} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    homePageDetails: state.homeDetails.homePageDetails.data
  };
};

export default connect(mapStateToProps)(OrgLogo);
