import * as  Actions from './payslipAction'

const initialState = {
    payslipData: {},
    viewedPayslipData: []
}

const payslipReducers = (state = initialState, action = {}) => {
    
    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.SET_PAYSLIP_DATA) {
        return {
            payslipData: action.payload.data,
            viewedPayslipData: [...state.viewedPayslipData, action.payload]
        }
    }
    else {
        return state
    }
}

export { payslipReducers }
export default payslipReducers
