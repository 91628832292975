import * as Actions from './saroodHospitalityAction'

const initialState = {
    res_restaurantList: [],
    res_selectedRestaurantId: "",
    res_selectedRestaurant: "",
    res_dileveyemail:"",
    res_dileveyemailTemplate:"",
    res_selectedRestaurantMinOrder: 0,
    res_selectedRestaurantDiscount: 0,
    res_categoryList: [],
    res_selectedCategory: "",
    res_allItems: [],
    res_selectedItems: [],
    res_totalAmount: 0,
    res_finalAmountDiscounted: 0,
    res_successMessage: "",
    res_deliveryAreaList: [],
    res_deliveryFormDetails: {},
    res_allProductsLoaded: false,
    res_isDeliveryAvailable: "",
    res_warningMessage: "",

}

const saroodHospitalityReducers = (state = initialState, action = {}) => {
    let discount = state.res_selectedRestaurantDiscount;
    switch (action.type) {
        case Actions.SET_RES_RESTAURANT_LIST:
            return {
                ...state,
                res_restaurantList: action.payload
            }
        case Actions.SET_RES_SELECTED_RESTAURANT_ID:
            return {
                ...state,
                res_selectedRestaurantId: action.payload
            }
        case Actions.SET_RES_SELECTED_RESTAURANT:
            return {
                ...state,
                res_selectedRestaurant: action.payload
            }
            case Actions.SET_RES_SELECTED_RESTAURANT_EMAIL:
                return {
                    ...state,
                    res_dileveyemail: action.payload
                }
                case Actions.SET_RES_SELECTED_RESTAURANT_EMAIL_TEMPLATE:
                return {
                    ...state,
                    res_dileveyemailTemplate: action.payload
                }
        case Actions.SET_RES_SELECTED_RESTAURANT_MINORDER:
            return {
                ...state,
                res_selectedRestaurantMinOrder: action.payload
            }
        case Actions.SET_RES_SELECTED_RESTAURANT_DISCOUNT:
            return {
                ...state,
                res_selectedRestaurantDiscount: action.payload
            }
        case Actions.SET_RES_CATEGORY_LIST:
            return {
                ...state,
                res_categoryList: [...state.res_categoryList, action.payload]
            }
        case Actions.SET_RES_SELECTED_CATEGORY:
            return {
                ...state,
                res_selectedCategory: action.payload
            }
        case Actions.SET_RES_ALL_PRODUCT_ITEMS:
            return {
                ...state,
                res_allItems: [...state.res_allItems, action.payload]
            }
        case Actions.SET_RES_SELECTED_ITEMS:
            return {
                ...state,
                res_selectedItems: action.payload
            }
        case Actions.SET_RES_TOTAL_AMOUNT:
            return {
                ...state,
                res_totalAmount: parseFloat(action.payload).toFixed(2),
                res_finalAmountDiscounted: parseFloat(action.payload - ((action.payload / 100) * discount)).toFixed(2)
            }
        case Actions.SET_RES_SUCCESS_MESSAGE:
            return {
                ...state,
                res_successMessage: action.payload
            }
        case Actions.SET_RES_DELIVERY_AREA_LIST:
            return {
                ...state,
                res_deliveryAreaList: action.payload
            }
        case Actions.SET_RES_DELIVERY_FORM_DETAILS:
            return {
                ...state,
                res_deliveryFormDetails: action.payload
            }
        case Actions.SET_RES_FLAG_ALL_PRODUCTS_LOADED:
            return {
                ...state,
                res_allProductsLoaded: action.payload
            }
        case Actions.SET_RES_IS_DELIVERY_AVAILABLE:
            return {
                ...state,
                res_isDeliveryAvailable: action.payload
            }
        case Actions.SET_RES_WARNING_MESSAGE:
            return {
                ...state,
                res_warningMessage: action.payload
            }
        default:
            return state
    }
}

export { saroodHospitalityReducers }
export default saroodHospitalityReducers
