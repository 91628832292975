import { combineReducers } from "redux";
import loginReducer from "../features/login/loginReducer";
import homeReducer from "../pages/home/homeReducer";
import generalReducer from "../features/general/generalReducer";
import digitalCardReducer from "../features/digitalCard/digitalCardReducer";
import leaveReducer from "../features/leave/leaveReducer";
import payslipReducers from "../features/payslip/payslipReducer";
import letterReducers from "../features/letter/letterReducer";
import letterHistoryReducers from "../features/letterHistory/letterHistoryReducer";
import prNotificationsReducers from "../features/prNotifications/prNotificationsReducers";
import poNotificationsReducers from "../features/poNotifications/poNotificationsReducers";
import leaveNotificationsReducers from "../features/leaveNotifications/leaveNotificationsReducers";
import dealDetailReducers from "../pages/dealDetail/dealDetailReducer";
import jobDetailReducers from "../pages/jobDetail/jobDetailReducer";
import eventDetailReducers from "../pages/eventDetail/eventDetailReducer";
import notificationsReducers from "../pages/service/notificationsReducer";
import featuredEventReducer from "../components/featuredEvent/featuredEventReducer";
import newsContentReducer from "../components/news/newsContentReducer";
import tileContentReducer from "../components/tileComponent/tileComponentReducer";
import discounttileContentReducer from "../components/cardComponent/tileComponentReducer";
import dealsCarouselReducer from "../components/dealsCarousel/dealsCarouselReducer";
import eventsCarouselReducer from "../components/eventsCarousel/eventsCarouselReducer";
import cafeReducer from "../features/cafeOffers/cafeOffersReducer";
import routesReducers from "./router/reducer";
import messagesReducer from "../features/chairmanMessage/messagesReducer";
import eventRegistrationReducer from "../features/eventRegistration/eventRegistrationReducer";
import pageTitleReducer from "../components/pageTitle/pageTitleReducer";
import emailRestrictionReducer from "../features/emailRestriction/emailRestrictionReducer";
import gusetDetailReducer from "../features/reservation/GuestDetailReducer";
import PaymentReducer from "../features/reservation/PaymentDetailComponent/PaymentReducer";
import HotelListReducer from "../features/reservation/HotelListReducer";
import CalendarResponeReducer from "../features/reservation/CalendarResponeReducer";
import RoomAvaliableResponseReducer from "../features/reservation/RoomAvaliableResponseReducer";
import SelectedSuiteReducer from "../features/reservation/SuitesDetailComponent/SelectedSuiteReducer";
import infoPopupReducer from "../components/infoPopup/infoPopupReducer";
import urbanFoodReducers from "../features/urbanFoods/urbanFoodReducer";
import saroodHospitalityReducers from "../features/saroodHospitality/saroodHospitalityReducer";
import SitecoreJBHDataReducer from "../features/reservation/HotelReservation/SitecoreJBHDataReducer";
import documentCenterReducer from "../components/documentCenter/documentCenterReducer";
import EmployeFeedbackReducer from "../features/employeefeedback/EmployeFeedbackReducer";
import spotonReducer from "../features/performance/spotonReducer";
import virtualBusinessCardReducer from "../features/virtualBusinessCard/virtualBusinessCardReducer";
import dhhqReducer from "../features/dhhq/dhhqReducer";
import emergencyReducer from "../features/dhhq/dhhqReducer";
import releaseNotesReducers from "../pages/releaseNotes/reducer";
import informationModalReducer from "../components/informationModal/informationModalReducer";

const reducer = combineReducers({
  loginDetails: loginReducer,
  homeDetails: homeReducer,
  generalDetails: generalReducer,
  digitalCardDetails: digitalCardReducer,
  leaveDetails: leaveReducer,
  payslipDetails: payslipReducers,
  letterDetails: letterReducers,
  letterHistoryDetails: letterHistoryReducers,
  dealDetails: dealDetailReducers,
  releaseNotes: releaseNotesReducers,
  jobDetails: jobDetailReducers,
  eventDetails: eventDetailReducers,
  featuredEventDetails: featuredEventReducer,
  newsContentDetails: newsContentReducer,
  tileContentDetails: tileContentReducer,
  discountContentDetails: discounttileContentReducer,
  notificationsDetails: notificationsReducers,
  prNotificationsDetails: prNotificationsReducers,
  poNotificationsDetails: poNotificationsReducers,
  leaveNotificationsDetails: leaveNotificationsReducers,
  dealContentDetails: dealsCarouselReducer,
  eventContentDetails: eventsCarouselReducer,
  cafeDetails: cafeReducer,
  routesDetails: routesReducers,
  messagesContentDetails: messagesReducer,
  eventRegistrationFormDetails: eventRegistrationReducer,
  pageDetails: pageTitleReducer,
  emailRestrictionDetails: emailRestrictionReducer,
  reservtionGuestCountDetail: gusetDetailReducer,
  paymentReducer: PaymentReducer,
  hotelListReducer: HotelListReducer,
  calendarResponeReducer: CalendarResponeReducer,
  roomAvaliableResponseReducer: RoomAvaliableResponseReducer,
  selectedSuiteReducer: SelectedSuiteReducer,
  infoPopupDetails: infoPopupReducer,
  urbanFoodDetails: urbanFoodReducers,
  saroodHospitalityDetails: saroodHospitalityReducers,
  sitecoreJBHDataReducer: SitecoreJBHDataReducer,
  documentCenterDetails: documentCenterReducer,
  employeFeedbackReducer: EmployeFeedbackReducer,
  spotonDetails: spotonReducer,
  contactDetails: virtualBusinessCardReducer,
  dhhqDetails:dhhqReducer,
  emergencyDetails:emergencyReducer,
  informationNotice:informationModalReducer
});

export default reducer;
