import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "../providers/reducer";

const middleware = [thunk];
const initialState = {};

const store = createStore(
    reducer,
    initialState,
    applyMiddleware(...middleware),
);

export default store;
