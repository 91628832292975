import React from 'react';
import { Label } from 'reactstrap';
import "./label.scss";

const LabelComponent = (props) => {
  return (
    <Label for={props.for} className={props.generic?"label-generic":"label-email"}>{props.text}</Label>
  );
}

export default LabelComponent;
