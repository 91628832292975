import React, { Component, Fragment } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import store from "../../providers/store";
import * as Action from "../../features/login/loginAction";
import LabelComponent from "../../components/label/label";
import azureAppConfig from "../../providers/azureAppConfig";
import InputComponent from "../../components/input/input";
import Loader from "../../components/loader/loader";
import { FormGroup } from "reactstrap";
import { oneAuthorizeAction, onePostAction, oneAuthorizeRenewAction } from "../../providers/action";
import RestConfig from "../../providers/restConfig.json";
import * as genericService from "../../providers/util";
import * as PostToNative from '../../providers/postToNative';
import "./login.scss";
import * as msal2 from "@azure/msal-browser";
const jwtDecode = require("jwt-decode");

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.initializeUserAgentApplication(null, null);
    var user = null;
    this.state = {
      isAuthenticated: user !== null,
      user: {},
      error: null,
      valid: localStorage.hasOwnProperty('lastEmail') ? true : false,
      touched: false,
      signInEmail: localStorage.hasOwnProperty('lastEmail') ? localStorage.getItem('lastEmail') : ""
    };
  }

  async login(email) {
    try {
      await this.userAgentApplication.loginRedirect({
        scopes: azureAppConfig.scopesv1,
        loginHint: email
      });
      await this.userAgentApplication.getActiveAccount();
    } catch (err) {
      this.setState({
        isAuthenticated: false,
        user: {},
        error: err
      });
    }
  }

  loggerCallback(logLevel, message, containsPii) {
    console.log(message);
  }

  initializeUserAgentApplication(email, tenantId) {
    if (localStorage.getItem("msal.error") !== null) {
      localStorage.clear();
    }
    let appId;
    let authority;

    if (localStorage.getItem("tenantType") === "DH") {
      appId = azureAppConfig.dh.appId;
      authority = azureAppConfig.dh.authority;
    }

    if (localStorage.getItem("tenantType") === "eahm") {
      appId = azureAppConfig.eahm.appId;
      authority = azureAppConfig.eahm.authority;
    }

    if (email !== null && email !== "") {
      let emailDomain = email.toLowerCase().split("@")[1];
      if (azureAppConfig.eahm.domains.includes(emailDomain)) {
        appId = azureAppConfig.eahm.appId;
        authority = azureAppConfig.eahm.authority;
        localStorage.setItem("tenantType", "eahm")
      } else {
        appId = azureAppConfig.dh.appId;
        authority = azureAppConfig.dh.authority;
        localStorage.setItem("tenantType", "DH");
      }
    }
    else if (tenantId !== null && tenantId !== "") {
      if (tenantId === azureAppConfig.eahm.tenantId) {
        appId = azureAppConfig.eahm.appId;
        authority = azureAppConfig.eahm.authority;
        localStorage.setItem("tenantType", "eahm")
      } else {
        appId = azureAppConfig.dh.appId;
        authority = azureAppConfig.dh.authority;
        localStorage.setItem("tenantType", "DH");
      }
    }
    this.userAgentApplication = new msal2.PublicClientApplication({
      auth: {
        clientId: appId,
        authority: authority,
        redirectUri: window.location.origin + '/',
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
      },
      system: {
        logger: new msal2.Logger(
          this.loggerCallback, {
          level: msal2.LogLevel.Verbose,
          piiLoggingEnabled: true,
          correlationId: '1234'
        }
        )
      }
    });
    store.dispatch({
      type: Action.SET_USERAGENT,
      payload: this.userAgentApplication
    });
    if (localStorage.getItem("tenantType") !== null) {
      let msalInstance = this.userAgentApplication
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      // Set active acccount on page load
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        if (accounts.length > 1) {
          // More than one user signed in, find desired user with getAccountByUsername(username)
          msalInstance.logoutRedirect();
        }
        else {
          msalInstance.setActiveAccount(accounts[0]);
          let tenantId = accounts[0].tenantId;
          this.getUserProfile(tenantId)
        }
      }

      msalInstance.addEventCallback((event) => {
        // set active account after redirect
        if (event.eventType === "msal:handleRedirectStart" && event.payload && event.payload.account) {
          const account = event.payload.account;
          msalInstance.setActiveAccount(account);
        }
      }, error => {
        console.log('error', error);
      });
      // handle auth redired/do all initial setup for msal
      msalInstance.handleRedirectPromise().then(authResult => {
        // Check if user signed in 
        const account = msalInstance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page 
          msalInstance.loginRedirect();
        }
      }).catch(err => {
        // TODO: Handle errors
        console.log(err);
      });
    }
  }

  refreshIdentity() {
    console.log("refreshIdentity")
    let userEnteredMail = this.state.signInEmail;
    this.setState({
      loginBtnClicked: true
    });
    this.initializeUserAgentApplication(userEnteredMail, null);
    this.login(userEnteredMail);
    localStorage.setItem("lastEmail", userEnteredMail)
  }
  refreshIdentityforLogin() {
    console.log("refreshIdentityforLogin")
    let userEnteredMail = this.state.signInEmail;
    if (localStorage.getItem("lastEmail") !== null) {
      userEnteredMail = localStorage.getItem("lastEmail");
    }
    this.setState({
      loginBtnClicked: true
    });
    this.login(userEnteredMail);
    localStorage.setItem("lastEmail", userEnteredMail)
  }


  async getUserProfile(tenantId) {
    try {
      let accessTokenv2;
      if (tenantId === azureAppConfig.eahm.tenantId) {
        accessTokenv2 = await this.userAgentApplication.acquireTokenSilent({
          scopes: azureAppConfig.eahmscopesv2
        })
      }
      else {
        accessTokenv2 = await this.userAgentApplication.acquireTokenSilent({
          scopes: azureAppConfig.scopesv2
        })
      }
      if (accessTokenv2) {
        this.setState({
          isAuthenticated: true,
          error: { message: "Access token:", debug: accessTokenv2.accessToken }
        });
        store.dispatch({
          type: Action.SET_AUTHENTICATED,
          payload: true
        });
        var accessTokenv1 = await this.userAgentApplication.acquireTokenSilent({
          scopes: azureAppConfig.scopesv1
        });
        let serviceRequest = {
          'v1accessToken': accessTokenv1.accessToken,
          'v2accessToken': accessTokenv2.accessToken
        };
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });

        this.props.oneAuthorizeAction('SET_USER_IDENTITY', serviceRequest)
          .then(userIdentity => {
            // Send Message to React Native after successful login
            let email = userIdentity.MAIL;
            let name = userIdentity.NAME;
            let entity = userIdentity.ENTITY;
            let userType = userIdentity.userType;
            const serviceType = RestConfig.filter(data => data.type === 'DEVICEREGISTER')[0].endpoint;
            const registerRequest = {
              requestType: "Register",
              data: {
                type: serviceType,
                token: localStorage.getItem("OneToken") || "",
                empParam: "",
                requestParam: {
                  "deviceID": "",
                  "deviceToken": "",
                  "deviceType": "web",
                  "emailID": email,
                  "entity": entity,
                  "name": name,
                }
              }
            }
            if (!window.getReactNativeApi) {
              this.props.onePostAction('SET_DEVICE_REGISTRATION', registerRequest).then(response => {
              })
                .catch(error => {
                  console.log("Error", error);
                });
            }

            PostToNative.onEmailReceivedEvent(email, name, entity);
          })
          .catch(error => {
            console.log("Error", error);
            if (localStorage.getItem('msal.error') === "consent_required") {
              document.cookie.split(";").forEach((c) => {
                document.cookie = c
                  .replace(/^ +/, "")
                  .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
              });
              localStorage.setItem('msal.error', '');
              window.location.reload(true);
            }
          });
      }
    } catch (err) {
      console.log(err);
      if (this.state.signInEmail !== "") {
        this.refreshIdentityforLogin();
      }
      this.setState({
        isAuthenticated: false,
        user: {},
        error: err
      });
    }
  }

  handleInputChange = event => {
    if (event) {
      const validValue = genericService.validateEmail(event.current.value);
      this.setState({
        valid: validValue,
        touched: true,
        signInEmail: event.current.value
      });
    }
  };
  handleBlurChange = event => {
    if (event) {
      const valid = genericService.validateEmail(event.current.value);
      if (valid) {
        this.setState({
          signInEmail: event.current.value
        });
      }
    }
  };

  handleKeyChange = event => {
    if (event.key === 'Enter') {
      const valid = genericService.validateEmail(event.target.value);
      if (valid) {
        this.setState({
          signInEmail: event.target.value
        }, () => {
          this.refreshIdentity();
        });
      }
    }
  }

  handleClearStorage = event => {
    console.log("inclear")
    localStorage.clear();
  };

  render() {

    let tenantType = localStorage.getItem("tenantType");
    return (
      <div className="loginContainer">
        <Container fluid={true}>
          <Row>
            <Col className="imageHolder" lg="8" md="12" sm="12" xs="12">
              <Hidden smDown>
                <div className="desktopBg">
                  <div className="desktopLogo">
                    <img src="/media/loginLogo.svg" alt="One Logo" />
                  </div>
                </div>
              </Hidden>
              <Hidden mdUp>
                <div className="mobileBg">
                  <div className="mobileLogo">
                    <img src="/media/loginLogo.svg" alt="One Logo" />
                  </div>
                </div>
              </Hidden>
            </Col>
            <Col lg="4" md="12" sm="12" xs="12" className="login-form">
              {/* <button className="cache-clear" onClick={this.handleClearStorage}>Clear Cache</button> */}
              {
                (this.state.isAuthenticated || tenantType !== null) ? <Loader /> :
                  <Fragment>
                    <Row>
                      <Col xs="12">
                        <LabelComponent for="sign in" text="Sign in" generic={true} />
                      </Col>
                    </Row>
                    <Row>
                      <Container fluid={true}>
                        <FormGroup>
                          <Row>
                            <Col xs="12">
                              <LabelComponent
                                for="email address"
                                text="Email address"
                              />
                            </Col>{" "}
                          </Row>{" "}
                          <Row>
                            <Col lg="12" md="12" sm="12">
                              <InputComponent
                                className="emaiText"
                                type="email"
                                name="email"
                                id="email-input"
                                placeholder="name@companydomain.com"
                                childRef={React.createRef()}
                                handleChange={this.handleInputChange}
                                handleBlur={this.handleBlurChange}
                                valid={this.state.valid}
                                invalid={!this.state.valid}
                                handleKey={this.handleKeyChange}
                                value={localStorage.hasOwnProperty('lastEmail') && !this.state.touched ? localStorage.getItem('lastEmail') : this.state.signInEmail}
                              />{" "}
                            </Col>{" "}
                            <Col lg="12" md="12" sm="12" className="buttonHolder">
                              <Button
                                color="danger"
                                onClick={() => this.refreshIdentity()}
                                size="md"
                                disabled={!this.state.valid}
                              >
                                <img src={"/media/login_next.png"} alt={'next'} />
                              </Button>{" "}
                            </Col>{" "}
                          </Row>{" "}
                        </FormGroup>{" "}
                      </Container>
                    </Row>
                  </Fragment>
              }

            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

LandingPage.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapStateToProps = state => {
  return {
    userAgentApplication: state.loginDetails.userAgentApplication,
    userTenant: state.loginDetails.userTenant
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      oneAuthorizeAction: oneAuthorizeAction,
      onePostAction: onePostAction,
      oneAuthorizeRenewAction: oneAuthorizeRenewAction
    },
    dispatch
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(LandingPage));