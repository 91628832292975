export const SET_RES_RESTAURANT_LIST = "SET_RES_RESTAURANT_LIST"
export const SET_RES_SELECTED_RESTAURANT_ID = "SET_RES_SELECTED_RESTAURANT_ID"
export const SET_RES_SELECTED_RESTAURANT = "SET_RES_SELECTED_RESTAURANT"
export const SET_RES_SELECTED_RESTAURANT_EMAIL = "SET_RES_SELECTED_RESTAURANT_EMAIL"
export const SET_RES_SELECTED_RESTAURANT_EMAIL_TEMPLATE = "SET_RES_SELECTED_RESTAURANT_EMAIL_TEMPLATE"
export const SET_RES_SELECTED_RESTAURANT_MINORDER = "SET_RES_SELECTED_RESTAURANT_MINORDER"
export const SET_RES_SELECTED_RESTAURANT_DISCOUNT = "SET_RES_SELECTED_RESTAURANT_DISCOUNT"
export const SET_RES_CATEGORY_LIST = "SET_RES_CATEGORY_LIST"
export const SET_RES_SELECTED_CATEGORY = "SET_RES_SELECTED_CATEGORY"
export const SET_RES_ALL_PRODUCT_ITEMS = "SET_RES_ALL_PRODUCT_ITEMS"
export const SET_RES_SELECTED_ITEMS = "SET_RES_SELECTED_ITEMS"
export const SET_RES_TOTAL_AMOUNT = "SET_RES_TOTAL_AMOUNT"
export const SET_RES_SUCCESS_MESSAGE = "SET_RES_SUCCESS_MESSAGE"
export const SET_RES_DELIVERY_AREA_LIST = "SET_RES_DELIVERY_AREA_LIST"
export const SET_RES_DELIVERY_FORM_DETAILS = "SET_RES_DELIVERY_FORM_DETAILS"
export const SET_RES_FLAG_ALL_PRODUCTS_LOADED = "SET_RES_FLAG_ALL_PRODUCTS_LOADED"
export const SET_RES_IS_DELIVERY_AVAILABLE = "SET_RES_IS_DELIVERY_AVAILABLE"
export const SET_RES_WARNING_MESSAGE = "SET_RES_WARNING_MESSAGE"
