import React from "react";
import Login from "../../features/login/login2";
import './Landing.scss';

const LandingPage=(props)=>{
  return(
    <Login/>
  )
}

export default LandingPage
