import * as Actions from './emailRestrictionAction';

const initialState = {
    emailRestrictionData: []
}

const emailRestrictionReducer = (state = initialState, action = {}) => {
    // Updated to if statement for sonar qube issue related to less conditional statements
    if (action.type === Actions.GET_EMAIL_RESTRICTION_DATA) {
        return {
            ...state,
            emailRestrictionData: action.payload
        }
    }    
    else {
        return state
    }
}

export default emailRestrictionReducer;
