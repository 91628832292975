export const validateEmail = (email) => {
    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
    if (!re.test(email)) {
        return false;
    }
    return true;
}

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";
    const absoluteamount = Math.abs(Number(amount) || 0)
    let i = parseInt(absoluteamount.toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "")
    )
}

export const isMobile = () => {
    return (
        /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    );
}

export const formatSiteCoreURL = url => {
    return url ? url.replace("/sitecore/shell/", "https://dxp-hospitality-dev-rg-sit-467300-cd2.azurewebsites.net/") : ''
}

export function base64ToBlobUrl(data, type) {
    if (data && type) {
        var byteString = window.atob(data);

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        // write the ArrayBuffer to a blob, and you're done
        let blob = new Blob([ab], { type: type });
        let blobUrl = window.URL.createObjectURL(blob);
        return blobUrl;
    } else {
        return ''
    }
    // convert base64 to raw binary data held in a string (Decode base64)
}

export async function blobUrlToBase64(blobUrl) {
    if (blobUrl === null) {
        return null;
    }
    let blob = await fetch(blobUrl).then(r => r.blob());
    let reader = new FileReader();
    return new Promise((resolve) => {
        reader.readAsDataURL(blob);
        reader.onload = () => {
            let dataUrl = reader.result;
            let base64 = dataUrl.split(',')[1];
            resolve(base64);
        };
    });
}

export const isEmptyObject = objData => {
    if (objData === undefined || objData === null) {
        return false;
    }
    return Object.keys(objData).length ? true : false;
}

export const getQueryParam = (location, data) => {
    const searchUrl = location.search
    const searchParams = searchUrl.replace("?", "").split('&')
    return (
        searchParams.length ? searchParams.filter(value => { return value.indexOf(data) > -1 })[0].split("=")[1] : ''
    );
}

export const checkIfFailureResponse = responseMessage => {
    const failureTexts = [
        'ora-',
        'error',
        'failure',
        'failed',
        'fail',
        'fails',
        'wrong',
        'invalid',
        'unknown',
    ]
    if (responseMessage) {
        return failureTexts.some(substring => responseMessage.toLowerCase().includes(substring)) ? true : false
    }
    if (responseMessage === undefined || responseMessage === '') {
        return false
    }
    return true
}

export const formatEntity = entity => {
    return entity === 'JG_DXB' ? entity.replace('_', '').split(" ").join("").toUpperCase() : entity.split(" ").join("").toUpperCase() || "";
}

const makeTwoDigit = function (number) {
    return (number < 10 ? '0' : '') + number;
}

export const getCurrentTime = () => {
    var today = new Date();
    var now = [today.getFullYear(),
    makeTwoDigit(today.getMonth() + 1),
    makeTwoDigit(today.getDate()),
    makeTwoDigit(today.getHours()),
    makeTwoDigit(today.getMinutes()),
    makeTwoDigit(today.getSeconds())];
    return now.join("-");
}

export const isObject = value => {
    return (!!value) && (value.constructor === Object);
}

export const isArray = value => {
    return (!!value) && (value.constructor === Array);
}

export const formatLink = text => {
    var mappedObj = {
        "&gt;": ">",
        "&lt;": "<"
    }
    if (text) {
        var textValue = text.replace(/&lt;|&gt;/gi, (matched) => {
            return mappedObj[matched];
        })
    }
    return textValue;
}

export const formatSearchImg = (cdnUrl, searchUrl) => {
    let newSearchUrl = searchUrl ? searchUrl.replace("/sitecore/shell", '') : '';
    let updatedUrl = cdnUrl + "/" + newSearchUrl;
    return updatedUrl;
}

export const extractAllowedUsers = (data, feature) => {
    let allowedUsersList = data.filter(item => item.feature === feature);
    allowedUsersList = allowedUsersList[0] ? allowedUsersList[0].allowedUsers : null;    
    return allowedUsersList;
}