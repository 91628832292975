import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Row, Col, Collapse } from 'reactstrap';
import './searchBar.scss';

const searchIcon = "/assets/homePage/searchIcon.svg";
const nextIcon = "/assets/icons/next.svg";
class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKey: '',
            searchLinkkey: '',
            visibility: false
        };
        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.setSearchkey = this.setSearchkey.bind(this);
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                visibility: false
            })
        }
        if (this.state.visibility) {
            this.searchInput.current.focus();
        }
    }

    setSearchkey(e) {
        if (e.target.value !== "") {
            this.setState({
                searchKey: e.target.value,
                searchLinkkey: e.target.value
            })
        }
        else {
            this.setState({
                searchKey: '',
                searchLinkkey: 'search'
            })
        }
    }

    toggleVisibility() {
        this.setState({
            visibility: !this.state.visibility,
            searchKey: '',
        })
    }

    setSearchkeyInParams = event => {
        if (event.key === "Enter") {
            this.props.history.push(`/colleagueSearch/${this.state.searchLinkkey}`)
            this.toggleVisibility()
        }
    }

    render() {
        return (
            <div className="searchBar">
                <Row className="searchBarRow">
                    <Col className="applyBorderBottom" xs="8">
                        <input
                            className="searchText"
                            type="text"
                            placeholder="Search employees"
                            onKeyPress={event => this.setSearchkeyInParams(event)}
                            onChange={this.setSearchkey}
                            value={this.state.searchKey} />
                    </Col>
                    <Col className="applyBorderBottom" xs="2">
                        <NavLink to={"/colleagueSearch/" + this.state.searchLinkkey} onClick={this.toggleVisibility} className="icon">
                            <img className="searchIcon" src={searchIcon} alt="Search" />
                        </NavLink>
                    </Col>
                    <Col xs="2" />
                </Row>
                <Row className="searchBarRowSmall">
                    <Col xs="12" className="searchIconContainer" onClick={this.toggleVisibility}>
                        {/* <img className="searchIcon" src={searchIcon} alt="Search" onClick={this.toggleVisibility} /> */}
                        <span className="material-icons person-search">person_search</span>
                        <img className="searchIcon person-eid" src="/assets/icons/person-search.png" alt="Search"/>
                    </Col>
                </Row>
                <Collapse isOpen={this.state.visibility} className="searchBoxHidden">
                    <Row className="applyPadding">
                        <Col className="applyBorderBottom" xs="9">
                            <input
                                className="searchText"
                                type="text"
                                placeholder="Search employees"
                                onKeyPress={event => this.setSearchkeyInParams(event)}
                                onChange={this.setSearchkey}
                                value={this.state.searchKey}
                                ref={this.searchInput} />
                        </Col>
                        <Col className="applyBorderBottom img" xs="3">
                            <NavLink to={"/colleagueSearch/" + this.state.searchLinkkey} onClick={this.toggleVisibility} className="icon">
                                <img className="searchIcon" src={nextIcon} alt="Search" />
                            </NavLink>
                        </Col>
                    </Row>
                    <div className="wrapperSection" onClick={this.toggleVisibility} />
                </Collapse>
            </div>
        );
    }
}

export default withRouter(SearchBar);
