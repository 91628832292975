import { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

const EmbedLink = () => {
  const history = useHistory();

  // App will force the link to open within Pgaeviewer(iframe) if it has a flag (embed) in class attribute
  const flag = "embed";
  const extraFlag = "allowNewWindow";

  const linkChecker = (node) => {
    let currentNodeName = node.tagName.toLowerCase();
    if (currentNodeName === "a") return node;
    let parent = node.parentNode;
    while (parent != null) {
      let parentName = parent.tagName !== undefined && parent.tagName.toLowerCase();
      if (parent.id === "root") return null;
      if (parentName === "a") return parent;
      parent = parent.parentNode;
    }
    return null;
  };

  const externalLinkChecker = useCallback((link) => {
    link = !!link ? link.toLowerCase() : "";
    const baseUrl = window.location.origin.toLowerCase();
    if (link === "") return false;
    if (link.startsWith("/")) return false;
    if (link.startsWith("#")) return false;
    if (link.startsWith(baseUrl)) return false;
    return true;
  }, []);

  const clickHandler = useCallback(
    (event) => {
      const currentTag = event.target;
      const anchorTag = linkChecker(currentTag);
      if (anchorTag !== null) {
        const currentLink = anchorTag.getAttribute("href");
        const isExternalLink = externalLinkChecker(currentLink);
        if (isExternalLink) {
          const className = anchorTag.getAttribute("class");
          if (className !== null && className.indexOf(flag) >= 0) {
            event.preventDefault();
            history.push("/pageview", {
              link: currentLink,
              allowNewwindow: className.indexOf(extraFlag) >= 0 ? true : false,
            });
          }
        }
      }
    },
    [externalLinkChecker, history]
  );

  useEffect(() => {
    document.body.addEventListener("click", (event) => clickHandler(event));
    return () =>
      document.body.removeEventListener("click", (event) =>
        clickHandler(event)
      );
  }, [clickHandler]);

  return null;
};

export default EmbedLink;
