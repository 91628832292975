import * as Actions from './tileComponentActions';

const initialState = {
    eventsContentData: {},
    dealfilter:"",
    eventfilter:"",
    jobfilter:"",
    discountfilter:""
    
}

const tileContentReducer = (state = initialState, action = {}) => {
    
    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.SET_CONTENT) {
        return {
            ...state,
            tileContentData: action.payload.data
        }
    }
    if (action.type === "SET_DEALFILTER") {
        
        return {
            ...state,
            dealfilter: action.payload
        }
    }
    if (action.type === "SET_JOBFILTER") {
        
        return {
            ...state,
            jobfilter: action.payload
        }
    }
    if (action.type === "SET_EVENTFILTER") {
        return {
            ...state,
            eventfilter: action.payload
        }
    }
    if (action.type === "SET_DISCOUNTFILTER") {
        return {
            ...state,
            discountfilter: action.payload
        }
    }

    else{
        return state
    }
}

export default tileContentReducer;
