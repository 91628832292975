import * as Actions from './homeAction';

const initialState = {
    homePageSiteCoreResponse: "",
    homePageDetails: { data: "" },
    cdnValueDetails: ""
}

const homeReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SET_SC_HOME_PAGE_RESPONSE:
            return {
                ...state,
                homePageSiteCoreResponse: action.payload
            }
        case Actions.SET_SC_HOME_PAGE_DETAILS:
            return {
                ...state,
                homePageDetails: action.payload
            }
        case Actions.SET_SC_HOME_PAGE_CDN_URL:
            return {
                ...state,
                cdnValueDetails: action.payload.data
            }
        default:
            return state
    }
}

export default homeReducer
