import * as  Actions from './letterHistoryActions'

const initialState = {
    letterHistoryData: []
}

const letterHistoryReducers = (state = initialState, action = {}) => {
    if (action.type === Actions.GET_LETTER_HISTORY) {
        return {
            letterHistoryData: action.payload.data,
        }
    }
    else {
        return state
    }
}

export { letterHistoryReducers }
export default letterHistoryReducers
