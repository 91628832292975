import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Redirect, Route } from "react-router-dom";
import { onePostAction, oneDispatchAction } from "../action";
import { getDHRoutes } from "./routes";
import * as Actions from "./actions";

class RouteComponents extends React.Component {
  componentDidMount = () => {
    this.getAuthorizedRoutes();
  };

  componentDidUpdate = () => {
    if (!Object.keys(this.props.authorizedRoutes).length) {
      this.getAuthorizedRoutes();
    }
  };

  getAuthorizedRoutes = () => {
    const entity =
      this.props.userIdentity && this.props.userIdentity.ENTITY
        ? this.props.userIdentity.ENTITY
        : "";
    let serviceRequest = {
      requestType: "Routes",
      data: {
        entity,
      },
    };
    let routes = {};
    this.props
      .onePostAction(Actions.GET_AUTHORIZED_ROUTES, serviceRequest)
      .then((response) => {})
      .catch((error) => {
        console.log("Error", error);
      });
    return routes;
  };

  render() {
    const DHroutes = getDHRoutes(this.props.authorizedRoutes);
    const routeComponents = DHroutes.map(
      ({ route, component, key, isExact }, index) => (
        <Route
          exact={isExact ? true : false}
          path={route}
          component={component}
          key={key}
        />
      )
    );
    return (
      <Switch>
        {routeComponents}
        <Redirect from="*" to="/" />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authorizedRoutes: state.routesDetails.authorizedRoutes,
    userIdentity: state.loginDetails.userIdentity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onePostAction: onePostAction,
      oneDispatchAction: oneDispatchAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteComponents);
