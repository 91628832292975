import {
  SET_USERAGENT,
  SET_AUTHENTICATED,
  SET_USERDETAIL,
  SET_USERTENANT,
  SET_USER_IDENTITY
} from "./loginAction";

const initialState = {
  userAgentApplication: undefined,
  authenticated: false,
  userDetail: null,
  userTenant: null,
  userIdentity:null
};

const loginReducer = (state = initialState, action={}) => {
  switch (action.type) {
    case SET_USERAGENT:
      return {
        ...state,
        userAgentApplication: action.payload
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload
      };
    case SET_USERDETAIL:
      return {
        ...state,
        userDetail: action.payload
      };
    case SET_USERTENANT:
      return {
        ...state,
        userTenant: action.payload
      };
    case SET_USER_IDENTITY:
      return {
        ...state,
        userIdentity: action.payload
      };

    default:
      return state;
  }
};

export default loginReducer;
