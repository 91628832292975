import React from "react";
import * as  Actions from './emailRestrictionAction';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { oneGetAction, oneDispatchAction } from '../../providers/action'

class EmailRestriction extends React.Component {

    constructor() {
        super()
        this.state = {
            isDetailsLoading: false,
            sitecoreItemId: "C829F682-E927-4EFB-992A-E65CE1794AFB"
        }
    }

    componentDidMount = () => {
        this.makeEventDetailsAPI()
    }

    makeEventDetailsAPI = () => {
        this.setState({
            isDetailsLoading: true,
        })
        let serviceRequest = {
            requestType: "SitecoreLayout",
            data: {
                type: "Detail",
                item: this.state.sitecoreItemId
            }
        };

        this.props
            .oneGetAction(Actions.SET_ER_RESPONSE, serviceRequest)
            .then(res => {
                let response = "";
                if (!!res.data) {
                    if (!!res.data.data) {
                        if (!!res.data.data.sitecore) {
                            if (!!res.data.data.sitecore.route) {
                                if (!!res.data.data.sitecore.route.fields["Service Email Restriction"]) {
                                    if (!!res.data.data.sitecore.route.fields["Service Email Restriction"].value) {
                                        response = res.data.data.sitecore.route.fields["Service Email Restriction"].value;
                                        response = decodeURIComponent(response);
                                        response = response.split("&");
                                        if (response.length > 0) {
                                            for (let i = 0; i < response.length; i++) {
                                                let feature = response[i].split("=")[0];
                                                let allowedUsers = response[i].split("=")[1];
                                                response[i] = {
                                                    feature: feature,
                                                    allowedUsers: allowedUsers
                                                }
                                            }
                                        }
                                        else {
                                            let feature = response.split("=")[0];
                                            let allowedUsers = response.split("=")[1];
                                            response = {
                                                feature: feature,
                                                allowedUsers: allowedUsers
                                            }
                                        }
                                        this.props.oneDispatchAction(Actions.GET_EMAIL_RESTRICTION_DATA, response);
                                    }
                                }
                            }
                        }
                    }
                }
                this.setState({
                    isDetailsLoading: false,
                })
            })
            .catch(error => {
                this.setState({
                    isDetailsLoading: false,
                })
                console.log("Error", error);
            });
    } 
   

    render() {
        return null
    }
}

const mapStateToProps = state => {
    return {
        emailRestrictionDetail: state.emailRestrictionDetails.emailRestrictionData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            oneGetAction: oneGetAction,
            oneDispatchAction: oneDispatchAction,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailRestriction);
