import * as Actions from "./featuredEventAction";

const initialState = {
    featuredEventData: {},
}

const featuredEventReducer = (state = initialState, action = {}) => {
    
    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.SET_FEATURED_EVENT_DATA) {
        return {
            featuredEventData: action.payload.fields
        }
    }
    else{
        return state
    }
}

export default featuredEventReducer;
