import * as Actions from "./HotelListAction";
const initialState = {
  calendarData: {},
  viewedCalendarData: [],
  calendarSelectedData: {},
};
const CalendarResponeReducer = (state = initialState, action = {}) => {
  if (action.type === Actions.SET_HOTEL_LIST_DATA_CALENDAR) {
    return {
      ...state,
      calendarData: action.payload.data,
      viewedCalendarData: [
        ...state.viewedCalendarData,
        action.payload.data.availabality_price,
      ],
    };
  } else if (action.type === Actions.SET_CALENDAR_DATA_SELECT) {
    return { ...state, calendarSelectedData: action.payload };
  } else {
    return state;
  }
};
export { CalendarResponeReducer };
export default CalendarResponeReducer;
