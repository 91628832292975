import React from "react";
import { Spinner } from "reactstrap";
import "./loader.scss";

function Loader(props) {
  return (
    <div className="loader">
      <div className="loader-text">Please wait ...</div>
      <div>
        <Spinner type="grow" color="info" />
        <Spinner type="grow" color="info" />
        <Spinner type="grow" color="info" />
      </div>
    </div>
  );
}

export default Loader;
