module.exports = {
  dh: {
    appId: '160f35e4-0a2a-4a7f-ad19-72263274f6e4',
    tenantId: 'eee3385e-742f-4e2e-b130-e496ed7d6a49',
    authority: 'https://login.microsoftonline.com/eee3385e-742f-4e2e-b130-e496ed7d6a49'
  },
  meraas: {
    appId: '27f9ee2c-567f-421c-90ff-b169d506dcb3',
    tenantId: '79ab0e33-53c5-49d3-8986-1497dcc82c7d',
    authority: 'https://login.microsoftonline.com/79ab0e33-53c5-49d3-8986-1497dcc82c7d',
    domains: ["meraas.ae", "valiant.ae", "meraasholding.onmicrosoft.com"]
  },
  dxbe: {
    appId: '4d1161a4-f389-4ce7-b1d6-368c1c5abc45',
    tenantId: '300b1200-9f01-45f5-9455-c3f6a04a64c9',
    authority: 'https://login.microsoftonline.com/300b1200-9f01-45f5-9455-c3f6a04a64c9',
    domains: ["dxbe.ae","dpronline.onmicrosoft.com","bollywoodparksdubai.com","dp-r.ae","dp-r.com","dubaiparksandresorts.com","dxbentertainments.com","legoland.ae","motiongatedubai.com","riverlanddubai.com"]
  },
  eahm: {
    appId: '2cf466c0-6eb7-4896-bb95-3107ffece7da',
    tenantId: 'c66e959c-be75-4ef1-9750-b69741c61c4f',
    authority: 'https://login.microsoftonline.com/c66e959c-be75-4ef1-9750-b69741c61c4f',
    domains: ["eahm.ae", "emiratesacademy.onmicrosoft.com"]
  },
  scopesv2: [
    "api://160f35e4-0a2a-4a7f-ad19-72263274f6e4/user_impersonation"
  ],
  meraasscopesv2: [
    "api://27f9ee2c-567f-421c-90ff-b169d506dcb3/user_impersonation"
  ],
  dxbescopesv2: [
    "api://4d1161a4-f389-4ce7-b1d6-368c1c5abc45/user_impersonation"
  ],
  eahmscopesv2: [
    "api://2cf466c0-6eb7-4896-bb95-3107ffece7da/user_impersonation"
  ],
  scopesv1: [
    "User.Read"
  ]
  
};

