import * as  Actions from './actions'

const initialState = {
    leaveNotifications: [],
    prNotifications: [],
    poNotifications: []
}

const notificationsReducers = (state = initialState, action = {}) => {
    
    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.GET_OPEN_NOTIFICATIONS) {
        return {
            leaveNotifications: action.payload.leaveNotifications,
            prNotifications: action.payload.prNotifications,
            poNotifications: action.payload.poNotifications,
            isLoading: action.payload.isLoading
        }
    }
    else {
        return state
    }
}

export { notificationsReducers }
export default notificationsReducers
