import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu';
import MenuLink from '../menuLink/menuLink';

class MenuToRight extends Component {

    state = {
        tr_menuOpen: false
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    tr_handleStateChange(state) {
        this.setState({ tr_menuOpen: state.isOpen })
    }

    // This can be used to close the menu, e.g. when a user clicks a menu item
    tr_closeMenu() {
        this.setState({ tr_menuOpen: false })
    }

    // This can be used to toggle the menu, e.g. when using a custom icon
    // Tip: You probably want to hide either/both default icons if using a custom icon
    // toggleMenu() {
    //     this.setState(state => ({ menuOpen: !state.menuOpen }))
    // }

    showSettings(tr_e) {
        tr_e.preventDefault();
    }

   

    render() {

        return (
            <div className="menuSection">
                <Menu isOpen={this.state.tr_menuOpen} onStateChange={(state) => this.tr_handleStateChange(state)}>
                    <MenuLink
                        onClick={() => this.tr_closeMenu()}
                        collection={this.props.collection}
                        restrictions={this.props.restrictions}
                        userAgent={this.props.userAgent}
                        userIdentity={this.props.userIdentity} />
                </Menu>
            </div>
        );
    }
}


export default MenuToRight;
