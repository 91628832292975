import * as Actions from './eventRegistrationAction';

const initialState = {
    eventRegistrationData: {},
    globalLimitAvailability: {},
    availabilityResponse: {}
}

const eventRegistrationReducer = (state = initialState, action = {}) => {
    // Updated to if statement for sonar qube issue related to less conditional statements
    if (action.type === Actions.GET_EVENT_REGISTRATION_FIELDS) {
        return {
            ...state,
            eventRegistrationData: action.payload
        }
    }
    else if (action.type === Actions.SET_ED_RESPONSE) {
        return {
            ...state,
            response: action.payload
        }
    }
    else if (action.type === Actions.GET_AVAILABILITY_DATES) {
        return {
            ...state,
            availabilityResponse: action.payload.data
        }
    }
    else if (action.type === Actions.GET_GLOBALLIMIT_AVAILABILITY) {
        return {
            ...state,
            globalLimitAvailability: action.payload.data
        }
    }
    else if (action.type === Actions.POST_FORM) {
        return {
            ...state,
            formSubmissionData: action.payload
        }
    }
    else {
        return state
    }
}

export default eventRegistrationReducer;
