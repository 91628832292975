import * as Actions from './prNotificationsActions'

const initialState = {
    prDetail: [],
    prAttachments: [],
  }

const prNotificationsReducers = (state = initialState, action={}) => {
    switch(action.type) {
    case Actions.GET_PR_DETAILS:
        return {
            prDetail: [action.payload, ...state.prDetail],
            prAttachments: state.prAttachments
        }
    case Actions.GET_PR_ATTACHMENTS:
        return {
            prDetail: state.prDetail,
            prAttachments: [action.payload, ...state.prAttachments]
        }
    default:
        return state
    }
}

export { prNotificationsReducers }
export default prNotificationsReducers
