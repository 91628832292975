import * as Actions from './newsContentAction';

const initialState = {
    newsContentData: {},
}

const newsContentReducer = (state = initialState, action = {}) => {
    
    // Updated to if statement for sonar qube issue related to less confitional statements
    if (action.type === Actions.SET_NEWS_CONTENT) {
        return {
            newsContentData: action.payload.data
        }
    }
    else{
        return state
    }
}

export default newsContentReducer;
