export const SET_HOTEL_LIST_DATA = "SET_HOTEL_LIST_DATA";
export const SET_HOTEL_LIST_DATA_CALENDAR = "SET_HOTEL_LIST_DATA_CALENDAR";

export const SET_CALENDAR_DATA_SELECT = "SET_CALENDAR_DATA_SELECT";

export const SET_HOTEL_LIST_DATA_ROOMAVALIABLE =
  "SET_HOTEL_LIST_DATA_ROOMAVALIABLE";

export const SET_SELECTED_HOTEL_DATA = "SET_SELECTED_HOTEL_DATA";

export const SET_SELECTED_HOTEL_ROOM_INFO = "SET_SELECTED_HOTEL_ROOM_INFO";

export const SET_SELECTED_SUITE = "SET_SELECTED_SUITE";
export const SET_SELECTED_SUITE_BAG = "SET_SELECTED_SUITE_BAG";
export const SET_SELECTED_SUITE_BAG_RESET = "SET_SELECTED_SUITE_BAG_RESET";

export const SET_PAYMENT = "SET_PAYMENT";
export const RESET_PAYMENT = "RESET_PAYMENT";

export const SET_PROMOCODE = "SET_PROMOCODE";
export const SET_JBH_CONFORMATION = "SET_JBH_CONFORMATION";
export const SET_JBH_SITECORE_DATA = "SET_JBH_SITECORE_DATA";
