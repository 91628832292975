const initialState = {
    externalLinks: []
}

const documentCenterReducer = (state = initialState, action = {}) => {
    if (action.type === "SET_EXTERNAL_LINKS") {
        return {
            ...state,
            externalLinks: action.payload
        }
    }
    return state;
}

export { documentCenterReducer }
export default documentCenterReducer
