import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Header from '../header/header';
import './errorBoundary.scss';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    reload() {
        this.props.history.push("/");
        window.location.reload(true);
    }

    render() {
        if (this.props.empMail !== this.props.empUpn && (this.props.empNum === null || this.props.empNum === "")) {
            return (
                <div className="errorBoundary">
                    <Header />
                    <p className="errorMessage">
                        Hi {this.props.userIdentity.NAME},<br /><br />Your email id is incorrect in Active Directory (Mail:{this.props.empMail},  UPN:{this.props.empUpn}). Please contact your local IT support to get this issue resolved.
                    </p>
                    <div className="buttonContainer">
                        <button
                            className="primary"
                            onClick={() => this.reload()}
                        >Refresh</button>
                    </div>
                </div>
            )
        }
        if (this.state.hasError) {
            // You can render any custom fallback UI
            if (this.props.empEntity === null || this.props.empEntity === undefined) {
                return (
                    <div className="errorBoundary">
                        <Header />
                        <p className="errorMessage">
                            Hi {this.props.userIdentity.NAME},<br /><br />
                            We are not able to retrieve your entity details. Please raise an ESD ticket to get this issue resolved from Microsoft Azure AD.
                        </p>
                        <div className="buttonContainer">
                            <button
                                className="primary"
                                onClick={() => this.reload()}
                            >Refresh</button>
                        </div>
                    </div>
                )
            }
            return (
                <div className="errorBoundary">
                    <Header />
                    <p className="errorMessage">Server could not respond,Kindly check with IT Team</p>
                    <p className="pathName">in "{this.props.history.location.pathname}"</p>
                    <div className="buttonContainer">
                        <button
                            className="primary"
                            onClick={() => this.reload()}
                        >Refresh</button>
                    </div>
                </div>
            )

        }

        return this.props.children;
    }
}

const mapStateToProps = state => {
    return {
        userIdentity: state.loginDetails.userIdentity,
        empEntity: state.loginDetails.userIdentity ? state.loginDetails.userIdentity.ENTITY : null,
        empMail: state.loginDetails.userIdentity ? state.loginDetails.userIdentity.MAIL : null,
        empUpn: state.loginDetails.userIdentity ? state.loginDetails.userIdentity.UPN : null,
        empNum: state.loginDetails.userIdentity ? state.loginDetails.userIdentity.EID : null
    };
};


export default withRouter(connect(mapStateToProps)(ErrorBoundary));
