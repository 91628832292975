import * as Actions from './infoPopupAction'

const initialState = {
    showPopup: true
  }

const infoPopupReducer = (state = initialState, action={}) => {
    if(action.type === Actions.SET_SHOW_INFOPOPUP) {   
        return {
            showPopup: action.payload
        }
    }
    else{
        return state
    }
}

export default infoPopupReducer
