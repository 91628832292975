import * as Actions from './urbanFoodAction'

const initialState = {
    parentCategoryList: [],
    selectedParentCategoryId: "",
    selectedParentCategory: "",
    subCategoryList: [],
    selectedSubCategory: "",
    allItems: [],
    selectedItems: [],
    totalAmount: 0,
    successMessage: "",
    deliveryAreaList: [],
    deliveryFormDetails: {},
    userSearchKey: "",
    userSearchCategory: "",
    allProductsLoaded: false,
    isDeliveryAvailable : ""
}

const urbanFoodReducers = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SET_PARENT_CATEGORY_LIST:
            return {
                ...state,
                parentCategoryList: action.payload
            }
        case Actions.SET_SELECTED_PARENT_CATEGORY_ID:
            return {
                ...state,
                selectedParentCategoryId: action.payload
            }
        case Actions.SET_SELECTED_PARENT_CATEGORY:
            return {
                ...state,
                selectedParentCategory: action.payload
            }
        case Actions.SET_SUB_CATEGORY_LIST:
            return {
                ...state,
                subCategoryList: [...state.subCategoryList, action.payload,]
            }
        case Actions.SET_SELECTED_SUB_CATEGORY:
            return {
                ...state,
                selectedSubCategory: action.payload
            }
        case Actions.SET_ALL_PRODUCT_ITEMS:
            return {
                ...state,
                allItems: [...state.allItems, action.payload,]
            }
        case Actions.SET_SELECTED_ITEMS:
            return {
                ...state,
                selectedItems: action.payload
            }
        case Actions.SET_TOTAL_AMOUNT:
            return {
                ...state,
                totalAmount: parseFloat(action.payload).toFixed(2)
            }
        case Actions.SET_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload
            }
        case Actions.SET_DELIVERY_AREA_LIST:
            return {
                ...state,
                deliveryAreaList: action.payload
            }
        case Actions.SET_DELIVERY_FORM_DETAILS:
            return {
                ...state,
                deliveryFormDetails: action.payload
            }
        case Actions.SET_USER_SEARCH_KEY:
            return {
                ...state,
                userSearchKey: action.payload
            }
        case Actions.SET_USER_SEARCH_CATEGORY:
            return {
                ...state,
                userSearchCategory: action.payload
            }
        case Actions.SET_FLAG_ALL_PRODUCTS_LOADED:
            return {
                ...state,
                allProductsLoaded: action.payload
            }
        case Actions.SET_IS_DELIVERY_AVAILABLE:
            return {
                ...state,
                isDeliveryAvailable: action.payload
            }
        default:
            return state
    }
}

export { urbanFoodReducers }
export default urbanFoodReducers
