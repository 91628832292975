import * as Actions from "./HotelListAction";

const initialState = {
  hotelListData: {},
  viewedHotelListData: [],
  hotelSelectedData: {},
  hotelRoomInfo:{},
  promoCode:""
};

const HotelListReducer = (state = initialState, action = {}) => {
  // Updated to if statement for sonar qube issue related to less confitional statements 
  if (action.type === Actions.SET_HOTEL_LIST_DATA) {
    return {
      ...state,
      hotelListData: action.payload.data,
      viewedHotelListData: [
        ...state.viewedHotelListData,
        action.payload.data.hotels_detail
      ]
    };
  } else if (action.type === Actions.SET_SELECTED_HOTEL_DATA) {
    return { ...state, hotelSelectedData: action.payload };
  }else if (action.type === Actions.SET_SELECTED_HOTEL_ROOM_INFO) {
    return { ...state, hotelRoomInfo: action.payload };
  }else if (action.type === Actions.SET_PROMOCODE) {
    return { ...state, promoCode: action.payload };
  }
   else {
    return {...state};
  }
};

export { HotelListReducer };
export default HotelListReducer;
